import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { getAuthInstance } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import {
  Edit2,
  ChevronRight,
  Zap,
  Check,
  X,
  Copy,
  Pencil,
  Link2,
  BarChart,
  Bell,
} from "lucide-react";
import { getSurvey, saveSurvey } from "./serverComm";
import debounce from "lodash/debounce";
import ProfileSettings from "./pages/ProfileSettings";
import PricingPage from "./PricingPage";
import { createPortal } from "react-dom";
import SurveyAnalytics from "./SurveyAnalytics";

const Logo = () => (
  <div className="h-14 w-[60px] flex items-center justify-center group">
    <svg
      width="55"
      height="55"
      viewBox="0 0 71 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.3937 36.3184H46.6395C46.6645 36.3761 46.6877 36.4356 46.7127 36.4933C40.6698 41.2183 34.627 45.9433 28.5859 50.67C28.5234 50.6385 28.4609 50.6071 28.3984 50.5756C29.2482 45.8453 30.0997 41.1133 30.9727 36.2537H23.0661C23.0446 36.1872 23.0214 36.1208 23 36.0543C28.9268 31.4185 34.8537 26.7862 40.9733 22C40.0914 26.8894 39.2577 31.5234 38.3937 36.3184Z"
        className="transition-colors duration-200 group-hover:fill-[#fffd63]"
        fill="#FFFD63"
        stroke="black"
        strokeWidth="9"
        strokeLinejoin="round"
        strokeLinecap="round"
        paintOrder="stroke fill"
      />
    </svg>
  </div>
);

const NotificationItem = ({ title, description, time, isNew }) => (
  <div
    className={`px-4 py-3 hover:bg-gray-50 cursor-pointer ${
      isNew ? "bg-blue-50/50" : ""
    }`}
  >
    <div className="flex justify-between items-start">
      <div className="flex-1">
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <p className="text-sm text-gray-500 mt-0.5">{description}</p>
      </div>
      {isNew && (
        <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
          New
        </span>
      )}
    </div>
    <p className="text-xs text-gray-400 mt-1">{time}</p>
  </div>
);

const ProfileDropdownItem = ({
  icon: Icon,
  label,
  onClick,
  variant = "default",
}) => (
  <button
    onClick={onClick}
    className={`flex w-full items-center px-4 py-2 text-sm transition-colors
      ${
        variant === "danger"
          ? "text-red-600 hover:bg-red-50"
          : "text-gray-700 hover:bg-gray-50"
      }`}
  >
    <Icon className="w-4 h-4 mr-3" />
    {label}
  </button>
);

const NavBar = React.memo(({ openSignIn }) => {
  const auth = getAuthInstance();
  const [user] = useAuthState(auth);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [isPollsDropdownOpen, setIsPollsDropdownOpen] = useState(false);
  const profileDropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { surveyId } = useParams();
  const [currentPoll, setCurrentPoll] = useState(null);
  const [currentPollName, setCurrentPollName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [saveStatus, setSaveStatus] = useState("idle"); // 'idle', 'saving', 'saved'
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [copyStatus, setCopyStatus] = useState("idle"); // 'idle', 'copying', 'copied'
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(true);
  const notificationsRef = useRef(null);
  const isSurveysPage = location.pathname === "/surveys";

  const debouncedSave = useCallback(
    debounce(async (newName, currentSurveyId, currentPoll) => {
      try {
        const surveyData = {
          surveyId: currentSurveyId,
          title: newName,
          questions: currentPoll?.questions || [],
          styles: currentPoll?.styles || {},
        };

        await saveSurvey(surveyData);

        const updatedSurvey = await getSurvey(currentSurveyId);
        setCurrentPoll(updatedSurvey);
        setCurrentPollName(updatedSurvey.title);
      } catch (error) {
        console.error("Error saving survey title:", error);
        setCurrentPollName(currentPoll?.title || "");
      }
    }, 1000),
    []
  );

  // Update the useEffect to handle both /survey/ and /s/ routes
  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        // Extract surveyId from either /survey/:id or /s/:id routes
        const pathParts = location.pathname.split("/");
        const currentSurveyId =
          pathParts[pathParts.indexOf("survey") + 1] || surveyId;

        if (currentSurveyId) {
          const survey = await getSurvey(currentSurveyId);
          setCurrentPoll(survey);
          setCurrentPollName(survey.title || "");
        } else {
          setCurrentPollName("");
        }
      } catch (error) {
        console.error("Error fetching survey details:", error);
        setCurrentPollName("");
      }
    };

    // Only fetch if we're on a survey route
    if (location.pathname.includes("/survey/")) {
      fetchSurveyDetails();
    } else {
      setCurrentPollName("");
    }
  }, [location.pathname, surveyId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setIsNotificationsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const isSurveyViewerRoute = location.pathname.startsWith("/s/");

  // Add useEffect to handle cleanup when navigating away
  useEffect(() => {
    // If we're not on a survey route, reset the analytics state
    if (!location.pathname.includes("/survey/")) {
      setShowAnalytics(false);
    }
  }, [location.pathname]);

  // Add cleanup to the existing navigate function
  const handleNavigateAway = () => {
    setShowAnalytics(false); // Reset analytics state before navigating
    navigate("/surveys");
  };

  if (isSurveyViewerRoute) {
    return null;
  }

  const handlePollNameChange = async (newName) => {
    try {
      // Replace with your actual update logic
      // await updatePollName(surveyId, newName);
      setCurrentPoll((prev) => ({ ...prev, title: newName }));
    } catch (error) {
      console.error("Error updating poll name:", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsProfileDropdownOpen(false);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleUpgradeClick = () => {
    setIsPricingModalOpen(true);
  };

  const handleNameEdit = () => {
    setIsEditingName(true);
  };

  const handleNameSave = async (newName) => {
    try {
      // Extract surveyId from the URL
      const pathParts = location.pathname.split("/");
      const currentSurveyId = pathParts[pathParts.indexOf("survey") + 1];

      if (!currentSurveyId) {
        throw new Error("Survey ID not found");
      }

      // Update local state immediately
      setCurrentPollName(newName);

      // Trigger debounced save
      debouncedSave(newName, currentSurveyId, currentPoll);
    } catch (error) {
      console.error("Error saving survey title:", error);
      setCurrentPollName(currentPoll?.title || "");
    }
  };

  const getSaveButtonContent = () => {
    switch (saveStatus) {
      case "saving":
        return (
          <>
            Saving...{" "}
            <Zap
              size={20}
              className="ml-2 animate-spin text-black fill-black"
              strokeWidth={2}
            />
          </>
        );
      case "saved":
        return (
          <>
            Saved! <Check size={20} className="ml-2" strokeWidth={2} />
          </>
        );
      default:
        return (
          <>
            Publish{" "}
            <Zap
              size={20}
              className="ml-2 text-black fill-black"
              strokeWidth={2}
            />
          </>
        );
    }
  };

  const handleSaveSurvey = async () => {
    try {
      const pathParts = location.pathname.split("/");
      const currentSurveyId = pathParts[pathParts.indexOf("survey") + 1];

      if (!currentSurveyId) {
        throw new Error("Survey ID not found");
      }

      setSaveStatus("saving");

      // Fetch current survey data first
      const currentSurveyData = await getSurvey(currentSurveyId);

      // Create the proper survey data object with all style properties
      const surveyData = {
        surveyId: currentSurveyId,
        title: currentPollName || currentSurveyData.title,
        questions: currentSurveyData.questions || [],
        styles: {
          ...currentSurveyData.styles,
          fontFamily: currentSurveyData.styles?.fontFamily || "Inter",
          submitButtonColor:
            currentSurveyData.styles?.submitButtonColor || "#4F46E5",
          backgroundColor:
            currentSurveyData.styles?.backgroundColor || "#FFFFFF",
          questionTextColor:
            currentSurveyData.styles?.questionTextColor || "#000000",
          optionTextColor:
            currentSurveyData.styles?.optionTextColor || "#4B5563",
          useCustomSubmitText:
            currentSurveyData.styles?.useCustomSubmitText || false,
          customSubmitText: currentSurveyData.styles?.customSubmitText || "",
          backgroundImage: currentSurveyData.styles?.backgroundImage || "",
          logo: currentSurveyData.styles?.logo || {
            url: "",
            size: "md",
            alignment: "left",
          },
        },
      };

      // Save survey with the proper data structure
      await saveSurvey(surveyData);

      setSaveStatus("saved");
      setTimeout(() => setSaveStatus("idle"), 2000);

      // Refresh the survey data
      const updatedSurvey = await getSurvey(currentSurveyId);
      setCurrentPoll(updatedSurvey);
      setCurrentPollName(updatedSurvey.title);
    } catch (error) {
      console.error("Error saving survey:", error);
      setSaveStatus("idle");
      alert("Failed to save survey. Please try again.");
    }
  };

  const handleCopyLink = () => {
    const link = `${window.location.origin}/s/${surveyId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopyStatus("copied");
        setTimeout(() => setCopyStatus("idle"), 2000);
      })
      .catch((error) => {
        console.error("Error copying survey link:", error);
        setCopyStatus("idle");
      });
  };

  const isSurveyDesignerRoute =
    location.pathname.includes("/survey/") &&
    !location.pathname.includes("/analytics");
  const currentTab = showAnalytics
    ? "results"
    : location.pathname.includes("/integrate")
    ? "integrate"
    : location.pathname.includes("/logic")
    ? "logic"
    : "create";

  // Get the current survey ID from the URL path if not available in params
  const currentSurveyId =
    surveyId || location.pathname.split("/survey/")[1]?.split("/")[0];

  // Debug log to check what user data we're getting

  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 ${
          isSurveyDesignerRoute ? "bg-[#f9fafb]" : "bg-white"
        }`}
        style={{ zIndex: 50 }}
      >
        <nav className="flex items-center justify-between h-14 w-full">
          <div className="flex items-center h-full flex-1">
            <button
              onClick={handleNavigateAway}
              className="h-14 w-[60px] flex items-center justify-center group"
            >
              <Logo />
            </button>

            <div className="relative ml-4">
              <div className="flex items-center space-x-2 text-sm font-medium">
                {location.pathname !== "/surveys" && (
                  <button
                    onClick={handleNavigateAway}
                    className="cursor-pointer hover:border-b-2 hover:border-gray-300 pb-[1px]"
                  >
                    All Forms
                  </button>
                )}
                {currentPollName && (
                  <>
                    <ChevronRight size={16} className="text-gray-400 mx-2" />
                    <div className="group relative inline-flex items-center">
                      {isEditingName ? (
                        <input
                          type="text"
                          defaultValue={currentPollName}
                          className="bg-transparent text-sm font-medium text-gray-900 border-0 border-b border-transparent hover:border-gray-300 focus:border-black focus:ring-0 px-0 py-0 -mt-0.5 min-w-[300px] transition-all"
                          onChange={(e) => handleNameSave(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.target.blur();
                              setIsEditingName(false);
                            }
                            if (e.key === "Escape") {
                              setIsEditingName(false);
                            }
                          }}
                          autoFocus
                          onFocus={(e) => e.target.select()}
                          onBlur={() => setIsEditingName(false)}
                        />
                      ) : (
                        <div className="flex items-center gap-1.5">
                          <span
                            onClick={handleNameEdit}
                            className="text-sm font-medium text-gray-900 cursor-text"
                          >
                            {currentPollName}
                          </span>
                          <Edit2
                            size={14}
                            className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                            onClick={handleNameEdit}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {isSurveyDesignerRoute && currentSurveyId && (
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <div className="flex items-center h-14 gap-4">
                <Link
                  to={`/survey/${currentSurveyId}`}
                  className={`flex items-center px-3 h-full text-sm font-medium transition-all border-t-2 pt-0 ${
                    currentTab === "create"
                      ? "border-black text-black"
                      : "border-transparent text-gray-600 hover:text-gray-900"
                  }`}
                  onClick={() => setShowAnalytics(false)}
                >
                  Create
                </Link>
                <Link
                  to={`/survey/${currentSurveyId}/logic`}
                  className={`flex items-center px-3 h-full text-sm font-medium transition-all border-t-2 pt-0 ${
                    currentTab === "logic"
                      ? "border-black text-black"
                      : "border-transparent text-gray-600 hover:text-gray-900"
                  }`}
                  onClick={() => setShowAnalytics(false)}
                >
                  Logic
                </Link>
                <Link
                  to={`/survey/${currentSurveyId}/integrate`}
                  className={`flex items-center px-3 h-full text-sm font-medium transition-all border-t-2 pt-0 ${
                    currentTab === "integrate"
                      ? "border-black text-black"
                      : "border-transparent text-gray-600 hover:text-gray-900"
                  }`}
                  onClick={() => setShowAnalytics(false)}
                >
                  Integrate
                </Link>
                <button
                  onClick={() => setShowAnalytics(true)}
                  className={`flex items-center px-3 h-full text-sm font-medium transition-all border-t-2 pt-0 ${
                    currentTab === "results"
                      ? "border-black text-black"
                      : "border-transparent text-gray-600 hover:text-gray-900"
                  }`}
                >
                  Results
                </button>
              </div>
            </div>
          )}

          <div className="flex items-center h-full flex-1 justify-end">
            {user && !user.isAnonymous ? (
              <>
                {location.pathname.includes("/survey/") &&
                  !location.pathname.includes("/survey/analytics") && (
                    <button
                      onClick={handleSaveSurvey}
                      disabled={saveStatus !== "idle"}
                      className={`flex items-center gap-2 px-3 py-1.5 text-sm font-medium rounded-md transition-colors mx-2
                      ${
                        saveStatus === "saved"
                          ? "bg-green-50 text-green-700"
                          : saveStatus === "saving"
                          ? "bg-black text-white"
                          : "bg-black text-white hover:bg-gray-800"
                      }`}
                    >
                      <span className="font-medium">
                        {saveStatus === "saving"
                          ? "Saving..."
                          : saveStatus === "saved"
                          ? "Saved!"
                          : "Publish"}
                      </span>
                      <Zap
                        size={16}
                        className={`
                        ${saveStatus === "saving" ? "animate-spin" : ""} 
                        ${saveStatus === "saved" ? "hidden" : ""}
                        fill-white
                        text-white
                      `}
                      />
                    </button>
                  )}

                <button
                  onClick={handleUpgradeClick}
                  className="hidden sm:flex items-center gap-2 px-4 h-full text-sm font-medium transition-all duration-300"
                >
                  View Plans
                </button>

                {isSurveysPage && (
                  <div className="relative" ref={notificationsRef}>
                    <button
                      onClick={() => {
                        setIsNotificationsOpen(!isNotificationsOpen);
                        setHasUnreadNotifications(false);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-lg relative mx-2"
                    >
                      <Bell size={20} className="text-gray-600" />
                      {hasUnreadNotifications && (
                        <span className="absolute top-1.5 right-1.5 w-2.5 h-2.5 bg-blue-500 rounded-full" />
                      )}
                    </button>

                    {isNotificationsOpen && (
                      <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg border border-gray-100 z-50">
                        <div className="px-4 py-2 border-b border-gray-100">
                          <h3 className="text-sm font-semibold text-gray-900">
                            Notifications
                          </h3>
                        </div>

                        <div className="max-h-[264px] overflow-y-auto">
                          <div className="divide-y divide-gray-100">
                            <NotificationItem
                              title="New Feature: Logic Flow"
                              description="Create complex survey logic with our new Logic Flow feature."
                              time="Just now"
                              isNew={true}
                            />
                            <NotificationItem
                              title="Survey Response Milestone"
                              description="Your 'Customer Feedback' survey has reached 100 responses!"
                              time="2 hours ago"
                              isNew={true}
                            />
                            <NotificationItem
                              title="Weekly Summary"
                              description="Check out your survey performance for the past week."
                              time="1 day ago"
                            />
                            <NotificationItem
                              title="New Template Available"
                              description="Try our new 'Employee Satisfaction' template."
                              time="3 days ago"
                            />
                            <NotificationItem
                              title="Feature Update"
                              description="Check out our new survey templates and themes."
                              time="5 days ago"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div
                  className="flex items-center ml-2 mr-4 relative"
                  ref={profileDropdownRef}
                >
                  <button
                    onClick={() =>
                      setIsProfileDropdownOpen(!isProfileDropdownOpen)
                    }
                    className="focus:outline-none"
                  >
                    <img
                      src={user?.photoURL || "https://via.placeholder.com/32"}
                      alt="Profile"
                      className="w-8 h-8 rounded-full object-cover"
                      referrerPolicy="no-referrer"
                    />
                  </button>

                  {isProfileDropdownOpen && (
                    <div className="absolute right-0 top-full mt-2 w-72 bg-white rounded-lg shadow-lg py-1.5 z-50 border border-gray-100 transform opacity-100 scale-100 transition-all duration-200 ease-out">
                      <div className="px-4 py-3 border-b border-gray-100">
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-semibold text-gray-900 truncate">
                            {user?.displayName || "User"}
                          </p>
                          <p className="text-sm text-gray-500 truncate">
                            {user?.email}
                          </p>
                        </div>
                      </div>

                      <div className="py-1.5 border-b border-gray-100">
                        <ProfileDropdownItem
                          icon={(props) => (
                            <svg
                              {...props}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                              />
                            </svg>
                          )}
                          label="Profile Settings"
                          onClick={() => {
                            setIsProfileModalOpen(true);
                            setIsProfileDropdownOpen(false);
                          }}
                        />
                      </div>

                      <div className="py-1.5 border-b border-gray-100">
                        <ProfileDropdownItem
                          icon={Link2}
                          label="API Documentation"
                          onClick={() => {
                            window.open("https://docs.example.com", "_blank");
                            setIsProfileDropdownOpen(false);
                          }}
                        />
                        <ProfileDropdownItem
                          icon={(props) => (
                            <svg
                              {...props}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          )}
                          label="Help & Support"
                          onClick={() => {
                            window.open(
                              "https://support.example.com",
                              "_blank"
                            );
                            setIsProfileDropdownOpen(false);
                          }}
                        />
                      </div>

                      <div className="py-1.5">
                        <ProfileDropdownItem
                          icon={(props) => (
                            <svg
                              {...props}
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                              />
                            </svg>
                          )}
                          label="Sign out"
                          variant="danger"
                          onClick={handleLogout}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <button
                onClick={openSignIn}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors mr-4"
              >
                Sign In
              </button>
            )}
          </div>
        </nav>
      </div>

      {/* Share Modal */}
      {showShareModal &&
        createPortal(
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            style={{ zIndex: 99999 }}
          >
            <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 m-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-gray-900">
                  Share Survey
                </h3>
                <button
                  onClick={() => setShowShareModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X size={20} />
                </button>
              </div>

              <div className="space-y-4">
                {/* Survey Link */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Survey Link
                  </label>
                  <div className="flex items-center gap-2">
                    <input
                      type="text"
                      readOnly
                      value={`${window.location.origin}/s/${surveyId}`}
                      className="flex-1 p-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                    <button
                      onClick={handleCopyLink}
                      className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      {copyStatus === "copied" ? (
                        <>
                          <Check size={16} className="text-green-500" />
                          Copied!
                        </>
                      ) : (
                        <>
                          <Copy size={16} />
                          Copy
                        </>
                      )}
                    </button>
                  </div>
                </div>

                {/* Share Options */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Share via
                  </label>
                  <div className="grid grid-cols-2 gap-3">
                    {[
                      {
                        name: "Email",
                        icon: "mail",
                        color: "bg-blue-50 text-blue-600",
                      },
                      {
                        name: "WhatsApp",
                        icon: "message-circle",
                        color: "bg-green-50 text-green-600",
                      },
                    ].map((option) => (
                      <button
                        key={option.name}
                        className={`flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors ${option.color}`}
                      >
                        <span className="text-sm font-medium">
                          {option.name}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Privacy Settings */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Privacy Settings
                  </label>
                  <select className="w-full p-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent">
                    <option value="public">
                      Public - Anyone with the link
                    </option>
                    <option value="private">
                      Private - Only specific people
                    </option>
                  </select>
                </div>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  onClick={() => setShowShareModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
                >
                  Close
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}

      <PricingPage
        isOpen={isPricingModalOpen}
        onClose={() => setIsPricingModalOpen(false)}
      />

      <ProfileSettings
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
      />

      {/* Add Analytics Modal */}
      {showAnalytics &&
        createPortal(
          <div className="fixed inset-0 bg-white z-40 mt-14">
            <div className="h-full overflow-auto">
              <SurveyAnalytics surveyId={currentSurveyId} />
            </div>
          </div>,
          document.body
        )}
    </>
  );
});

export default NavBar;
