import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  GripVertical,
  Trash2,
  Edit2,
  Check,
  X,
  Star,
  Settings,
  Plus,
  ChevronLeft,
  ChevronRight,
  ArrowRight,
} from "lucide-react";
import LogicConfigurator from "./LogicConfigurator";
import { motion, AnimatePresence } from "framer-motion";

const CustomStar = ({ size, fill, className, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill ? "currentColor" : "none"}
    stroke="currentColor"
    strokeWidth="1.5"
    className={className}
    {...props}
  >
    <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" />
  </svg>
);

const LogoComponent = ({ logo }) => {
  if (!logo?.url) return null;

  return (
    <div className="w-full transition-all duration-300 ease-in-out mb-2">
      <div
        className={`
          transition-all duration-300 ease-in-out
          ${logo.alignment === "center" ? "mx-auto" : ""}
          ${logo.alignment === "right" ? "ml-auto" : ""}
          ${logo.size === "sm" ? "max-w-[100px]" : ""}
          ${logo.size === "md" ? "max-w-[150px]" : ""}
          ${logo.size === "lg" ? "max-w-[200px]" : ""}
        `}
      >
        <img
          src={logo.url}
          alt="Survey Logo"
          className="w-full h-auto object-contain transition-all duration-300"
        />
      </div>
    </div>
  );
};

const QuestionRenderer = React.memo(
  ({
    question = {},
    answer,
    onAnswerChange,
    isDesignerPreview = false,
    onQuestionUpdate,
    onNavigate,
    isLastQuestion,
    currentQuestionIndex,
    totalQuestions,
    showProgress = true,
    responses = [],
    styles = {},
    navigationDirection = "next",
    questionNumber,
    isSubmitting,
  }) => {
    const [editingText, setEditingText] = useState(false);
    const [questionText, setQuestionText] = useState(question.text || "");
    const [editingOptionIndex, setEditingOptionIndex] = useState(null);
    const [options, setOptions] = useState(
      question.type === "multiple choice"
        ? question.options?.length > 0
          ? question.options
          : []
        : []
    );
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [editingValue, setEditingValue] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const editableRef = useRef(null);

    const memoizedResponses = useMemo(() => {
      return responses.map((response) => ({
        ...response,
        formatted: formatResponse(response),
      }));
    }, [responses]);

    useEffect(() => {
      if (question) {
        setQuestionText(question.text || "");
        setOptions(
          question.type === "multiple choice"
            ? question.options?.length > 0
              ? question.options
              : []
            : []
        );
      }
    }, []);

    useEffect(() => {
      if (isEditing && editableRef.current) {
        editableRef.current.focus();
        // Place cursor at the end
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(editableRef.current);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }, [isEditing]);

    // Check if question is empty (no real question added yet)
    const isEmptyQuestion = !question.text && !question.type;

    // Return null if it's an empty question to hide the preview
    if (isEmptyQuestion && isDesignerPreview) {
      return null;
    }

    const handleTextSave = () => {
      if (onQuestionUpdate) {
        onQuestionUpdate({
          ...question,
          text: questionText,
        });
      }
      setEditingText(false);
    };

    const handleOptionSave = (index, newValue) => {
      const newOptions = [...options];
      newOptions[index] = newValue;
      if (onQuestionUpdate) {
        onQuestionUpdate({
          ...question,
          options: newOptions,
        });
      }
      setOptions(newOptions);
      setEditingOptionIndex(null);
    };

    const handleAddOption = () => {
      const newOptions = [...options, "New Option"];
      setOptions(newOptions);
      if (onQuestionUpdate) {
        onQuestionUpdate({
          ...question,
          options: newOptions,
        });
      }
      setEditingOptionIndex(newOptions.length - 1);
    };

    const handleDeleteOption = (indexToDelete) => {
      const newOptions = options.filter((_, index) => index !== indexToDelete);
      setOptions(newOptions);
      if (onQuestionUpdate) {
        onQuestionUpdate({
          ...question,
          options: newOptions,
        });
      }
    };

    const handleTextClick = () => {
      if (isDesignerPreview) {
        setEditingText(true);
      }
    };

    const handleOptionClick = (index) => {
      if (isDesignerPreview) {
        setEditingOptionIndex(index);
      }
    };

    const handleOptionPaste = (e, index) => {
      const pastedText = e.clipboardData.getData("text");

      // Split the pasted text into array by newlines or commas
      const pastedOptions = pastedText
        .split(/[\n,]/)
        .map((opt) => opt.trim())
        .filter((opt) => opt.length > 0);

      if (pastedOptions.length > 1) {
        e.preventDefault();

        // Update options with the pasted list
        const newOptions = [
          ...options.slice(0, index),
          ...pastedOptions,
          ...options.slice(index + 1),
        ];

        setOptions(newOptions);
        if (onQuestionUpdate) {
          onQuestionUpdate({
            ...question,
            options: newOptions,
          });
        }
        setEditingOptionIndex(null);
      }
    };

    const getLetterForIndex = (index) => {
      return String.fromCharCode(65 + index); // 65 is ASCII for 'A'
    };

    const renderAnswerInput = () => {
      switch (question.type) {
        case "short text":
        case "long text":
          return (
            <input
              type="text"
              placeholder="Enter your answer"
              className={`w-full p-2 bg-transparent border-0 
              ${
                answer
                  ? "border-b-2 border-black border-opacity-60 text-black text-opacity-60"
                  : "border-b border-black border-opacity-60 text-black text-opacity-60"
              }
              focus:outline-none focus:ring-0 focus:border-b-2 focus:border-black focus:border-opacity-60 focus:text-opacity-60
              placeholder-black placeholder-opacity-60
              transition-all`}
              disabled={isDesignerPreview}
              value={answer || ""}
              onChange={(e) => onAnswerChange?.(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && answer?.trim()) {
                  setTimeout(() => {
                    onNavigate?.("next");
                  }, 300);
                }
              }}
            />
          );

        case "star rating":
          return (
            <div className="flex flex-col items-center gap-2">
              <div className="flex items-center gap-4">
                {[1, 2, 3, 4, 5].map((star) => (
                  <div key={star} className="flex flex-col items-center">
                    <CustomStar
                      size={56}
                      onClick={() => {
                        if (!isDesignerPreview) {
                          onAnswerChange?.(star);
                          // Add slight delay before moving to next question
                          setTimeout(() => {
                            onNavigate?.("next");
                          }, 300);
                        }
                      }}
                      onMouseEnter={() =>
                        !isDesignerPreview && setHoverRating(star)
                      }
                      onMouseLeave={() =>
                        !isDesignerPreview && setHoverRating(0)
                      }
                      className={`${!isDesignerPreview ? "cursor-pointer" : ""} 
                      transition-all duration-200
                      ${
                        hoverRating >= star || answer >= star
                          ? "text-black"
                          : "text-black text-opacity-60"
                      }
                      ${
                        answer >= star
                          ? "animate-[flash_0.2s_ease-in-out_2]"
                          : ""
                      }`}
                      fill={hoverRating >= star || answer >= star}
                      style={{
                        pointerEvents: isDesignerPreview ? "none" : "auto",
                      }}
                    />
                    <span
                      className={`text-sm mt-1 ${
                        hoverRating >= star || answer >= star
                          ? "text-black"
                          : "text-black text-opacity-60"
                      }`}
                    >
                      {star}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );

        case "numeric":
          return (
            <input
              type="number"
              placeholder="Enter a number"
              className={`w-full p-2 bg-transparent border-0 
              ${
                answer
                  ? "border-b-2 border-black border-opacity-60 text-black text-opacity-60"
                  : "border-b border-black border-opacity-60 text-black text-opacity-60"
              }
              focus:outline-none focus:ring-0 focus:border-b-2 focus:border-black focus:border-opacity-60 focus:text-opacity-60
              placeholder-black placeholder-opacity-60
              transition-all`}
              disabled={isDesignerPreview}
              value={answer || ""}
              onChange={(e) => onAnswerChange?.(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && answer?.toString().trim()) {
                  setTimeout(() => {
                    onNavigate?.("next");
                  }, 300);
                }
              }}
            />
          );

        case "multiple choice":
        case "checkbox list":
          return (
            <div
              className={`
            flex flex-col items-start
            ${styles.answerSpacing || "space-y-2"}
            ${styles.answerContainerPadding || "py-4"}
          `}
            >
              {(question.options || []).map((option, index) => {
                const isSelected =
                  question.type === "checkbox list"
                    ? Array.isArray(answer) && answer.includes(option)
                    : answer === option;

                return (
                  <div
                    key={index}
                    onClick={() => {
                      if (!isDesignerPreview && editingOptionIndex !== index) {
                        if (question.type === "checkbox list") {
                          const newAnswer = Array.isArray(answer)
                            ? [...answer]
                            : [];
                          if (isSelected) {
                            const index = newAnswer.indexOf(option);
                            newAnswer.splice(index, 1);
                          } else {
                            newAnswer.push(option);
                          }
                          onAnswerChange?.(newAnswer);
                        } else {
                          onAnswerChange?.(option);
                          setTimeout(() => {
                            onNavigate?.("next");
                          }, 300);
                        }
                      }
                    }}
                    className={`
                    relative group flex items-center rounded-lg
                    border transition-all duration-200 overflow-hidden
                    ${!isDesignerPreview ? "cursor-pointer" : ""}
                    ${
                      !isDesignerPreview && isSelected
                        ? "border-blue-500 text-blue-700 bg-white animate-[flash_0.2s_ease-in-out]"
                        : "border-gray-200 hover:border-gray-300 hover:bg-gray-50 bg-white bg-opacity-20"
                    }
                    w-fit
                    ${styles.answerPadding || "p-3"}
                    ${styles.answerMargin || "my-1"}
                    ${styles.answerFontSize || "text-sm"}
                    ${styles.answerLineHeight || "leading-normal"}
                  `}
                    style={{ maxWidth: "480px" }}
                  >
                    <div
                      className={`
                    ${styles.radioSize || "w-6 h-6"} 
                    rounded-md 
                    border border-gray-300
                    flex items-center justify-center mr-3
                    ${
                      isSelected
                        ? "bg-blue-500 text-white border-blue-500"
                        : "bg-white text-gray-600"
                    }
                  `}
                    >
                      {isSelected ? (
                        <Check size={styles.compact ? 12 : 16} />
                      ) : (
                        getLetterForIndex(index)
                      )}
                    </div>
                    {editingOptionIndex === index ? (
                      <div
                        className="flex-1"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          type="text"
                          value={editingValue}
                          onChange={(e) => setEditingValue(e.target.value)}
                          onFocus={(e) => {
                            setEditingValue(option);
                            e.target.select();
                          }}
                          onBlur={() => {
                            if (editingValue.trim() !== "") {
                              const newOptions = [...(question.options || [])];
                              newOptions[index] = editingValue;
                              if (onQuestionUpdate) {
                                onQuestionUpdate({
                                  ...question,
                                  options: newOptions,
                                });
                              }
                            }
                            setEditingOptionIndex(null);
                            setEditingValue("");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              if (editingValue.trim() !== "") {
                                const newOptions = [
                                  ...(question.options || []),
                                ];
                                newOptions[index] = editingValue;
                                if (onQuestionUpdate) {
                                  onQuestionUpdate({
                                    ...question,
                                    options: newOptions,
                                  });
                                }
                              }
                              setEditingOptionIndex(null);
                              setEditingValue("");
                              e.preventDefault();
                            } else if (e.key === "Escape") {
                              setEditingOptionIndex(null);
                              setEditingValue("");
                              e.preventDefault();
                            }
                          }}
                          className="w-full py-0 px-0 border-none focus:ring-0 bg-transparent text-gray-900 focus:outline-none"
                          placeholder="Type an option"
                          autoFocus
                        />
                      </div>
                    ) : (
                      <span
                        className={`flex-1 py-0.5 px-0.5 rounded hover:bg-gray-50 ${
                          isDesignerPreview ? "cursor-text" : ""
                        }`}
                        onClick={(e) => {
                          if (isDesignerPreview) {
                            e.stopPropagation();
                            setEditingOptionIndex(index);
                            setEditingValue(option);
                          }
                        }}
                      >
                        {option}
                      </span>
                    )}
                    {!isDesignerPreview && isSelected && (
                      <Check size={20} className="text-blue-500 ml-2" />
                    )}
                    {isDesignerPreview && (
                      <div
                        className="opacity-0 group-hover:opacity-100 transition-opacity"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <button
                          onClick={(e) => {
                            const newOptions = question.options.filter(
                              (_, i) => i !== index
                            );
                            if (onQuestionUpdate) {
                              onQuestionUpdate({
                                ...question,
                                options: newOptions,
                              });
                            }
                          }}
                          className="p-1.5 text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-all"
                        >
                          <X size={16} strokeWidth={2.5} />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}

              {isDesignerPreview && (
                <button
                  onClick={() => {
                    const newOptions = [
                      ...(question.options || []),
                      "New Option",
                    ];
                    if (onQuestionUpdate) {
                      onQuestionUpdate({
                        ...question,
                        options: newOptions,
                      });
                    }
                    setEditingOptionIndex(newOptions.length - 1);
                  }}
                  className={`
                  w-fit min-w-[200px] text-gray-600 border border-dashed border-gray-300 
                  rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-1
                  ${styles.answerPadding || "px-4 py-2"}
                  ${styles.answerFontSize || "text-sm"}
                `}
                >
                  <Plus size={styles.compact ? 12 : 14} />
                  Add Option
                </button>
              )}
            </div>
          );

        default:
          return (
            <div className="space-y-2">
              {options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => !isDesignerPreview && onAnswerChange?.(option)}
                  className={`
                  relative group flex items-center p-3 rounded-lg
                  border transition-all duration-200 overflow-hidden
                  ${!isDesignerPreview ? "cursor-pointer" : ""}
                  ${
                    !isDesignerPreview && answer === option
                      ? "border-blue-500 text-blue-700 animate-[flash_0.2s_ease-in-out_2] bg-white"
                      : "border-gray-200 hover:border-gray-300 hover:bg-gray-50 bg-white bg-opacity-20"
                  }
                `}
                >
                  <div
                    className={`
                  w-6 h-6 
                  rounded-md 
                  border border-gray-300
                  flex items-center justify-center mr-3
                  ${
                    answer === option
                      ? "bg-blue-500 text-white border-blue-500"
                      : "bg-white text-gray-600"
                  }
                `}
                  >
                    {getLetterForIndex(index)}
                  </div>
                  {editingOptionIndex === index ? (
                    <div className="flex-1">
                      <input
                        type="text"
                        value={options[index]}
                        onChange={(e) => {
                          const newOptions = [...options];
                          newOptions[index] = e.target.value;
                          setOptions(newOptions);
                        }}
                        onBlur={() => handleOptionSave(index, options[index])}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleOptionSave(index, options[index]);
                          }
                        }}
                        onPaste={(e) => handleOptionPaste(e, index)}
                        className="w-full p-1 border-none focus:ring-0 bg-transparent text-gray-900"
                        autoFocus
                      />
                    </div>
                  ) : (
                    <span
                      className="flex-1"
                      onClick={() => handleOptionClick(index)}
                    >
                      {option}
                    </span>
                  )}
                  {!isDesignerPreview && answer === option && (
                    <Check size={20} className="text-blue-500 ml-2" />
                  )}
                  {isDesignerPreview && (
                    <div className="opacity-0 group-hover:opacity-100 transition-opacity">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteOption(index);
                        }}
                        className="p-1.5 text-gray-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-all"
                      >
                        <X size={16} strokeWidth={2.5} />
                      </button>
                    </div>
                  )}
                </div>
              ))}

              {isDesignerPreview && question.type === "multiple choice" && (
                <button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  onClick={handleAddOption}
                  className="w-full px-4 py-2 text-sm text-gray-600 border border-dashed border-gray-300 
                  rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center gap-1"
                >
                  <Plus size={14} />
                  Add Option
                </button>
              )}
            </div>
          );
      }
    };

    const renderProgressBar = () => {
      if (!showProgress || !isDesignerPreview) return null;

      return (
        <div className="absolute top-0 left-0 right-0 w-full">
          <div className="w-full bg-gray-200 h-1">
            <div
              className="bg-blue-500 h-1 transition-all duration-300"
              style={{
                width: `${
                  ((currentQuestionIndex + 1) / totalQuestions) * 100
                }%`,
              }}
            />
          </div>
          <div className="text-xs text-gray-500 text-center mt-2">
            Question {currentQuestionIndex + 1} of {totalQuestions}
          </div>
        </div>
      );
    };

    const renderNavigationButtons = () => {
      if (!isLastQuestion || isDesignerPreview) return null;

      return (
        <div className="flex justify-center mt-8">
          <button
            onClick={() => onNavigate("next")}
            disabled={isSubmitting}
            className={`
            flex items-center gap-2 px-8 py-3 rounded-xl text-white font-medium 
            transition-all ${
              isSubmitting ? "opacity-70 cursor-not-allowed" : ""
            }
          `}
            style={{ backgroundColor: styles?.submitButtonColor || "#4F46E5" }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      );
    };

    const renderMainContent = () => (
      <>
        {/* Question Container */}
        <div className="mb-4">
          <div className="w-full max-w-2xl mx-auto">
            <div className="flex gap-3 items-start">
              {/* Question Number with Arrow */}
              <div className="flex items-center gap-2 text-gray-500 pt-1">
                <span className="font-medium">{questionNumber}</span>
                <ArrowRight size={14} className="text-gray-400" />
              </div>

              {/* Question Text */}
              <div
                ref={editableRef}
                contentEditable={isDesignerPreview}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onClick={handleQuestionClick}
                className={`
                font-medium leading-relaxed outline-none flex-1
                empty:before:content-['Type_a_question'] 
                empty:before:text-gray-400
                ${isDesignerPreview ? "cursor-text hover:text-gray-700" : ""}
                ${styles.questionFontSize || "text-xl"}
              `}
                style={{ color: styles?.questionTextColor || "#000000" }}
                suppressContentEditableWarning={true}
              >
                {question.text}
              </div>
            </div>
          </div>
        </div>

        {/* Options Container */}
        <div className="ml-[42px]">{renderAnswerInput()}</div>

        {/* Submit Button */}
        {renderNavigationButtons()}
      </>
    );

    const handleQuestionClick = () => {
      if (isDesignerPreview) {
        setIsEditing(true);
      }
    };

    const handleBlur = () => {
      setIsEditing(false);
      const newText = editableRef.current.innerText.trim();
      if (newText !== question.text) {
        onQuestionUpdate({
          ...question,
          text: newText,
        });
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        editableRef.current.blur();
      }
      if (e.key === "Escape") {
        editableRef.current.innerText = question.text;
        editableRef.current.blur();
      }
    };

    const formatResponse = (response) => {
      // Add formatting logic here if needed
      return response;
    };

    return (
      <motion.div
        key={question.id}
        custom={navigationDirection}
        initial={{
          opacity: 0,
          y: navigationDirection === "next" ? "-50vh" : "50vh", // Enter from top when going next
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: navigationDirection === "prev" ? "-50vh" : "50vh", // Exit to bottom when going next
        }}
        transition={{
          type: "tween",
          duration: 0.35,
          ease: "easeOut",
          opacity: {
            duration: 0.2,
          },
        }}
        className="relative h-full"
      >
        {renderProgressBar()}
        <div className="mt-2">
          <div className="p-4">{renderMainContent()}</div>
        </div>
      </motion.div>
    );
  }
);

export default QuestionRenderer;
