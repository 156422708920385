import React from "react";
const ResponseLimitAlert = ({ subscription, onUpgradeClick }) => {
  const getPlanLimit = () => {
    if (!subscription) return "2";

    const { limit, name } = subscription;

    // Adjust the logic based on your subscription names
    switch (name) {
      case "Pro Plan":
        return `${limit.toLocaleString()} per survey`;
      case "Growth Plan":
        return "unlimited";
      case "Free Trial":
      default:
        return limit.toLocaleString();
    }
  };
  return (
    <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
      <div className="flex items-start">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Response Limit Reached
          </h3>
          <p className="text-sm text-red-700 mt-1">
            You've reached the response limit for your{" "}
            {subscription?.name || "Free Plan"} plan ({getPlanLimit()}{" "}
            responses). Please upgrade your plan to continue collecting
            responses.
          </p>
          <button
            onClick={() => onUpgradeClick()}
            className="mt-2 text-sm font-medium text-red-800 hover:text-red-900"
          >
            Upgrade Plan →
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponseLimitAlert;
