import {
  CardNumberElement,
  ElementsConsumer,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { getAuthInstance } from "../../firebase";
import { Loader } from "lucide-react";

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://surv-server.vercel.app";
const AddPaymentForm = ({ onClose, customerName, customerEmail }) => {
  const auth = getAuthInstance();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e, stripe, elements) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      if (!stripe || !elements) {
        throw new Error("Stripe has not loaded yet");
      }

      const cardElement = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardElement, {
        name: customerName,
      });

      if (error) {
        throw new Error(error.message);
      }

      // Send token to backend
      const response = await fetch(`${API_URL}/api/stripe/add-payment-method`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
        body: JSON.stringify({
          token: token.id,
          customerName,
          customerEmail,
          userId: auth.currentUser.uid,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add payment method");
      }

      const data = await response.json();
      if (data.success) {
        onClose();
      }
    } catch (err) {
      console.error("Error adding payment method:", err);
      setError(
        err.message || "Failed to add payment method. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <form
          onSubmit={(e) => handleSubmit(e, stripe, elements)}
          className="space-y-4"
        >
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Card Number
              </label>
              <div className="border border-gray-300 rounded-md p-3">
                <CardNumberElement />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Expiry Date
                </label>
                <div className="border border-gray-300 rounded-md p-3">
                  <CardExpiryElement />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  CVC
                </label>
                <div className="border border-gray-300 rounded-md p-3">
                  <CardCvcElement />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm bg-black text-white rounded-md hover:bg-black/90 relative"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Loader className="w-4 h-4 animate-spin" />
                </div>
              ) : (
                "Add Card"
              )}
            </button>
          </div>
        </form>
      )}
    </ElementsConsumer>
  );
};

export default AddPaymentForm;
