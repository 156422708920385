import React, { useState, useEffect } from "react";
import {
  CreditCard,
  Check,
  Shield,
  Star,
  Zap,
  Info,
  Trash2,
  Loader,
  ExternalLink,
} from "lucide-react";
import SubscriptionStatus from "../SubscriptionStatus";
import { getAuthInstance } from "../../firebase";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";

const auth = getAuthInstance();
const stripePromise = loadStripe(
  "pk_test_51NUwEkA00utCSExRUfexEaAeoS4LU5HC2vFXwyps0HZVyBWQzR9Whv1lbAKiDqudZ9ttyk4YWj5g18gYhMPVzHmV00tnvJFzt1"
);

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://surv-server.vercel.app";

const PlanFeature = ({ text }) => (
  <div className="flex items-center gap-2">
    <Check className="w-4 h-4 text-gray-400" />
    <span className="text-gray-600 text-sm">{text}</span>
  </div>
);

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  planName,
  isDowngrade,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]">
      <div
        className="bg-white p-8 rounded-3xl shadow-xl max-w-[480px] w-full mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>

        <div className="text-center mb-8">
          <h3 className="text-3xl font-medium mb-2 font-['HK_Nova']">
            {isDowngrade ? "Downgrade Plan" : "Upgrade Plan"}
          </h3>
          <p className="text-gray-500">
            Are you sure you want to {isDowngrade ? "downgrade" : "upgrade"} to
            the {planName} plan?
            {isDowngrade && (
              <>
                <br />
                This will reduce your available features and limits.
              </>
            )}
          </p>
        </div>

        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="w-1/2 h-14 text-lg rounded-xl border border-gray-200 hover:bg-gray-50 transition-all font-medium"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className={`w-1/2 h-14 text-lg rounded-xl text-white transition-all font-medium
              ${
                isDowngrade
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-black hover:bg-black/90"
              }`}
          >
            {isDowngrade ? "Downgrade" : "Upgrade"} to {planName}
          </button>
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ isOpen, onClose, planName }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]">
      <div
        className="bg-white p-8 rounded-3xl shadow-xl max-w-[480px] w-full mx-4"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>

        <div className="text-center mb-8">
          <div className="w-16 h-16 bg-[#ECFDF3] rounded-full flex items-center justify-center mx-auto mb-4">
            <Check className="w-8 h-8 text-[#027A48]" />
          </div>
          <h3 className="text-3xl font-medium mb-2 font-['HK_Nova']">
            Success!
          </h3>
          <p className="text-gray-500">
            Your subscription has been successfully updated to the {planName}{" "}
            plan.
            <br />
            The changes are now active.
          </p>
        </div>

        <button
          onClick={onClose}
          className="w-full h-14 text-lg rounded-xl bg-black text-white hover:bg-black/90 transition-all font-medium"
        >
          Got it
        </button>
      </div>
    </div>
  );
};

const ErrorModal = ({ isOpen, onClose, error }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999]">
      <div className="bg-white rounded-lg p-6 w-96">
        <h3 className="text-lg font-semibold mb-4">Error</h3>
        <p className="text-sm text-red-600 mb-4">{error}</p>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm bg-black text-white rounded-md hover:bg-black/90"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const PlanCard = ({
  id,
  name,
  price,
  features,
  current,
  color,
  isAnnual,
  onSubscribe,
  currentSubscription,
  hasPaymentMethod,
  onNeedsPaymentMethod,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDowngrade =
    (name === "Free" && currentSubscription) ||
    (name === "Pro" && currentSubscription?.name === "Growth");

  const handleSubscribeClick = () => {
    if (!hasPaymentMethod && name !== "Free") {
      onNeedsPaymentMethod();
      return;
    }
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    try {
      await onSubscribe(id);
      setShowSuccess(true);
    } catch (err) {
      setError(
        err.message || "Failed to update subscription. Please try again."
      );
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full overflow-visible rounded-2xl border border-gray-200 bg-white relative">
      {isLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
          <Loader className="w-6 h-6 animate-spin" />
        </div>
      )}
      <div className={`${color} p-3 rounded-t-2xl`}>
        <div className="flex items-center justify-between">
          <div className="text-left">
            <h2 className="text-base font-medium">{name}</h2>
            <p className="text-xs text-gray-600">
              {name === "Free"
                ? "Up to 3 Surveys"
                : name === "Pro"
                ? "100 Surveys"
                : "Ultimate Features"}
            </p>
          </div>
          {name !== "Free" && isAnnual && (
            <div className="inline-flex bg-white h-5 px-1.5 items-center rounded-full border border-black">
              <span className="font-bold text-[10px] whitespace-nowrap">
                20% OFF
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="p-3">
        <div className="flex items-baseline justify-between mb-2">
          <div className="flex items-baseline">
            <span className="text-sm">$</span>
            <span className="text-2xl font-bold">{price}</span>
            <span className="text-xs text-gray-500 ml-1">/mon</span>
          </div>
          {current && (
            <span className="text-[10px] px-1.5 py-0.5 bg-black text-white rounded-full">
              Current
            </span>
          )}
        </div>

        <button
          className={`w-full h-8 text-xs rounded-md mb-3 ${
            current
              ? "border border-black"
              : "bg-black text-white hover:bg-black/90"
          }`}
          disabled={current || isLoading}
          onClick={handleSubscribeClick}
        >
          {isLoading ? (
            <Loader className="w-4 h-4 animate-spin mx-auto" />
          ) : current ? (
            "Current plan"
          ) : name === "Free" && currentSubscription ? (
            "Downgrade to Free"
          ) : name === "Pro" && currentSubscription?.name === "Growth" ? (
            "Downgrade to Pro"
          ) : (
            `Upgrade to ${name}`
          )}
        </button>

        <div className="space-y-1.5">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center gap-1.5">
              <Check className="w-3 h-3 text-gray-400" />
              <span className="text-gray-600 text-xs flex items-center">
                {Array.isArray(feature) ? (
                  <>
                    {feature[0]}
                    <span className="group relative inline-flex items-center">
                      {feature[1]}
                      <span className="invisible group-hover:visible absolute -translate-x-1/2 -translate-y-full -top-2 left-1/2 w-64 bg-black text-white text-xs rounded-md py-2 px-3 z-[9999]">
                        Facebook Pixel, Google Analytics, Google Tag Manager,
                        Slack
                      </span>
                    </span>
                  </>
                ) : (
                  feature
                )}
              </span>
            </div>
          ))}
        </div>
      </div>

      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirm}
        planName={name}
        isDowngrade={isDowngrade}
      />

      <SuccessModal
        isOpen={showSuccess}
        onClose={() => {
          setShowSuccess(false);
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }}
        planName={name}
      />

      <ErrorModal
        isOpen={showError}
        onClose={() => setShowError(false)}
        error={error}
      />
    </div>
  );
};

const AddPaymentForm = ({ onClose, customerName, customerEmail }) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e, stripe, elements) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      if (!stripe || !elements) {
        throw new Error("Stripe has not loaded yet");
      }

      const cardElement = elements.getElement(CardNumberElement);
      const { token, error } = await stripe.createToken(cardElement, {
        name: customerName,
      });

      if (error) {
        throw new Error(error.message);
      }

      // Send token to backend
      const response = await fetch(`${API_URL}/api/stripe/add-payment-method`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
        },
        body: JSON.stringify({
          token: token.id,
          customerName,
          customerEmail,
          userId: auth.currentUser.uid,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add payment method");
      }

      const data = await response.json();
      if (data.success) {
        onClose();
      }
    } catch (err) {
      console.error("Error adding payment method:", err);
      setError(
        err.message || "Failed to add payment method. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <form
          onSubmit={(e) => handleSubmit(e, stripe, elements)}
          className="space-y-4"
        >
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Card Number
              </label>
              <div className="border border-gray-300 rounded-md p-3">
                <CardNumberElement />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Expiry Date
                </label>
                <div className="border border-gray-300 rounded-md p-3">
                  <CardExpiryElement />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  CVC
                </label>
                <div className="border border-gray-300 rounded-md p-3">
                  <CardCvcElement />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm bg-black text-white rounded-md hover:bg-black/90 relative"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Loader className="w-4 h-4 animate-spin" />
                </div>
              ) : (
                "Add Card"
              )}
            </button>
          </div>
        </form>
      )}
    </ElementsConsumer>
  );
};

const AddPaymentModal = ({ isOpen, onClose, customerName, customerEmail }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h3 className="text-lg font-semibold mb-4">Add Payment Method</h3>
        <Elements stripe={stripePromise}>
          <AddPaymentForm
            onClose={onClose}
            customerName={customerName}
            customerEmail={customerEmail}
          />
        </Elements>
      </div>
    </div>
  );
};

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  cardDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!isOpen) return null;

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h3 className="text-lg font-semibold mb-4">Confirm Deletion</h3>
        <p className="text-sm text-gray-600 mb-4">
          Are you sure you want to remove this card?
          <br />
          <span className="font-medium">
            {cardDetails.brand} ending in {cardDetails.last4}
          </span>
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            disabled={isLoading}
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 text-sm bg-red-500 text-white rounded-md hover:bg-red-600 relative"
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="flex items-center justify-center">
                <Loader className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              "Delete Card"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const BillingTab = () => {
  const [isAnnual, setIsAnnual] = useState(true);
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    show: false,
    cardId: null,
    cardDetails: null,
  });
  const [products, setProducts] = useState([]);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  const fetchPaymentMethods = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/stripe/payment-methods`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setPaymentMethods(data.paymentMethods);
    } catch (err) {
      console.error("Error fetching payment methods:", err);
    }
  };

  const fetchTransactions = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/stripe/transactions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTransactions(data.transactions);
    } catch (err) {
      console.error("Error fetching transactions:", err);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_URL}/api/stripe/products`);
      const data = await response.json();
      setProducts(data.products);
    } catch (err) {
      console.error("Error fetching products:", err);
    }
  };

  const fetchCurrentSubscription = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${API_URL}/api/stripe/current-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setCurrentSubscription(data.subscription);
    } catch (err) {
      console.error("Error fetching current subscription:", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchPaymentMethods(),
        fetchTransactions(),
        fetchProducts(),
        fetchCurrentSubscription(),
      ]);
      setIsLoading(false);
    };

    fetchData();
    setCustomerName(auth.currentUser?.displayName || "");
    setCustomerEmail(auth.currentUser?.email || "");
  }, [showAddPayment]); // Refetch when modal closes

  const handleRemovePaymentMethod = async (paymentMethodId) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${API_URL}/api/stripe/remove-payment-method`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ paymentMethodId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to remove payment method");
      }

      setDeleteConfirmation({ show: false, cardId: null, cardDetails: null });
      // Refresh payment methods list
      fetchPaymentMethods();
    } catch (err) {
      console.error("Error removing payment method:", err);
    }
  };

  const handleSubscribe = async (productId) => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/stripe/subscribe`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productId,
          isAnnual,
          customerName,
          customerEmail,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update subscription. Please try again.");
      }

      // Refresh data after successful subscription
      await Promise.all([
        fetchPaymentMethods(),
        fetchTransactions(),
        fetchCurrentSubscription(),
      ]);
    } catch (err) {
      throw err;
    }
  };

  const plans = [
    {
      id: null, // Free plan has no Stripe product ID
      name: "Free",
      price: 0,
      current: !currentSubscription || currentSubscription.name === "Free",
      color: "bg-[#FFFEF2]",
      features: [
        "3 surveys total",
        "30 responses total",
        "Basic design only",
        "Email support",
      ],
    },
    ...products
      .filter((p) => ["Pro", "Growth"].includes(p.name))
      .map((product) => ({
        id: product.id,
        name: product.name,
        price: isAnnual ? product.monthlyPrice * 0.8 : product.monthlyPrice,
        current: currentSubscription?.name === product.name,
        color: product.name === "Pro" ? "bg-[#FFFD63]" : "bg-[#D3FBF1]",
        features:
          product.name === "Pro"
            ? [
                "100 surveys total",
                "1,000 responses per survey",
                "Custom branding",
                [
                  "Basic integrations",
                  <Info
                    className="w-4 h-4 text-gray-400 inline-block ml-0.5 cursor-help"
                    data-tooltip="Facebook Pixel, Google Analytics, Google Tag Manager, Slack"
                  />,
                ],
                "Standard technical support",
              ]
            : [
                "Unlimited surveys and responses",
                "Team collaboration",
                "Advanced analytics",
                "Advanced integrations (API Access)",
                "Priority technical support",
                "Custom onboarding",
              ],
      })),
  ];

  const getPlanBackgroundColor = (planName) => {
    switch (planName) {
      case "Pro":
        return "bg-[#FFFD63]";
      case "Growth":
        return "bg-[#D3FBF1]";
      case "Free":
      default:
        return "bg-[#FFFEF2]";
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-96">
        <Loader className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="space-y-4 animate-fadeIn">
      <div
        className={`border border-gray-200 rounded-lg p-4 ${getPlanBackgroundColor(
          currentSubscription?.name
        )}`}
      >
        <SubscriptionStatus subscription={currentSubscription} />
      </div>

      {/* Plans */}
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h3 className="text-base font-semibold">Available Plans</h3>

          {/* Billing Toggle */}
          <div className="inline-flex items-center rounded-[20px] bg-white border border-[#E4E4E4] p-1">
            <div className="flex items-center space-x-1">
              <div
                className={`px-2 py-1 rounded-[16px] transition-colors duration-300 cursor-pointer text-xs font-medium
                ${isAnnual ? "text-black" : "text-gray-400"}`}
                onClick={() => setIsAnnual(true)}
              >
                <span
                  className={`relative z-10 ${
                    isAnnual
                      ? "bg-[#FFFD63] -mx-2 -my-1 px-2 py-1 rounded-[16px] block"
                      : ""
                  }`}
                >
                  Annual (20% off)
                </span>
              </div>

              <div
                className={`px-2 py-1 rounded-[16px] transition-colors duration-300 cursor-pointer text-xs font-medium
                ${!isAnnual ? "text-black" : "text-gray-400"}`}
                onClick={() => setIsAnnual(false)}
              >
                <span
                  className={`relative z-10 ${
                    !isAnnual
                      ? "bg-[#FFFD63] -mx-2 -my-1 px-2 py-1 rounded-[16px] block"
                      : ""
                  }`}
                >
                  Monthly
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2">
          {plans
            .sort((a, b) => {
              const order = { Free: 1, Pro: 2, Growth: 3 };
              return order[a.name] - order[b.name];
            })
            .map((plan) => (
              <PlanCard
                key={plan.name}
                {...plan}
                isAnnual={isAnnual}
                onSubscribe={handleSubscribe}
                currentSubscription={currentSubscription}
                hasPaymentMethod={paymentMethods.length > 0}
                onNeedsPaymentMethod={() => setShowAddPayment(true)}
              />
            ))}
        </div>
      </div>

      {/* Payment Methods */}
      <div className="border border-gray-200 rounded-lg p-3">
        <h3 className="text-base font-semibold mb-2">Payment Methods</h3>
        <div className="space-y-2">
          {paymentMethods.length > 0 ? (
            <div className="space-y-2">
              {paymentMethods?.map((method) => (
                <div
                  key={method.id}
                  className="flex items-center justify-between text-sm bg-gray-50 p-2 rounded-md"
                >
                  <div className="flex items-center space-x-2">
                    <CreditCard size={16} className="text-gray-400" />
                    <span className="capitalize">{method?.brand}</span>
                    <span className="text-gray-500">•••• {method?.last4}</span>
                    <span className="text-gray-500">
                      {String(method?.expMonth).padStart(2, "0")}/
                      {String(method?.expYear).slice(-2)}
                    </span>
                    {method?.isDefault && (
                      <span className="text-xs bg-black text-white px-2 py-0.5 rounded-full">
                        Default
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() =>
                      setDeleteConfirmation({
                        show: true,
                        cardId: method.id,
                        cardDetails: {
                          brand: method.brand,
                          last4: method.last4,
                        },
                      })
                    }
                    className="text-red-500 hover:text-red-600 p-1"
                    title="Remove payment method"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-500 text-xs">
              No payment methods added yet
            </div>
          )}
          <button
            className="flex items-center space-x-1.5 text-black hover:text-gray-700 font-medium text-xs pt-[10px] pl-[8px]"
            onClick={() => setShowAddPayment(true)}
          >
            <CreditCard size={14} />
            <span>Add Payment Method</span>
          </button>
        </div>
      </div>

      {/* Billing History */}
      <div className="border border-gray-200 rounded-lg p-3">
        <h3 className="text-base font-semibold mb-2">Billing History</h3>
        {transactions.length > 0 ? (
          <div className="space-y-2">
            {transactions.map((transaction) => (
              <div
                key={transaction.id}
                className="flex items-center justify-between text-sm bg-gray-50 p-2 rounded-md p-[15px]"
              >
                <div className="flex items-center space-x-4">
                  <div>
                    <div className="font-medium">{transaction.description}</div>
                    <div className="text-xs text-gray-500">
                      {new Date(transaction.date).toLocaleDateString()}
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <div className="font-medium">${transaction.amount}</div>
                  {transaction.receiptUrl && (
                    <a
                      href={transaction.receiptUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-500 hover:text-gray-700"
                      title="View Receipt"
                    >
                      <ExternalLink size={16} />
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-gray-500 text-xs">
            No billing history available
          </div>
        )}
      </div>

      <div className="space-y-0">
        <AddPaymentModal
          isOpen={showAddPayment}
          onClose={() => setShowAddPayment(false)}
          customerName={customerName}
          customerEmail={customerEmail}
        />

        <DeleteConfirmationModal
          isOpen={deleteConfirmation.show}
          onClose={() =>
            setDeleteConfirmation({
              show: false,
              cardId: null,
              cardDetails: null,
            })
          }
          onConfirm={() => handleRemovePaymentMethod(deleteConfirmation.cardId)}
          cardDetails={deleteConfirmation.cardDetails}
        />
      </div>
    </div>
  );
};

export default BillingTab;
