import React, { useState, useCallback, useRef } from "react";
import { Upload, Image, Trash2, Eye, Loader2, Grid, List } from "lucide-react";
import { useDropzone } from "react-dropzone";
import Message from "./Message";
import UploadProgress from "./MediaLibraryTab/UploadProgress";
import EmptyState from "./MediaLibraryTab/EmptyState";
import {
  uploadMediaFile,
  getMediaFiles,
  deleteMediaFile,
} from "../../serverComm";
import { Tooltip } from "../../components/ui/Tooltip";
import ConfirmationModal from "../../components/ui/ConfirmationModal";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const MediaLibraryTab = () => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [uploadProgress, setUploadProgress] = useState({});
  const [viewMode, setViewMode] = useState("grid");
  const [previewFiles, setPreviewFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    fileId: null,
  });

  const fetchMediaFiles = async () => {
    try {
      setIsLoading(true);
      const files = await getMediaFiles();
      setMediaFiles(files);
    } catch (error) {
      setMessage({ type: "error", text: "Error fetching media files" });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchMediaFiles();
  }, []);

  const validateFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      return `${file.name} is too large. Maximum size is 10MB`;
    }
    if (!file.type.startsWith("image/")) {
      return `${file.name} is not an image file`;
    }
    return null;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const validFiles = [];
    const errors = [];

    // Validate files and create previews
    acceptedFiles.forEach((file) => {
      const error = validateFile(file);
      if (error) {
        errors.push(error);
      } else {
        validFiles.push(file);
        // Create preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewFiles((prev) => [
            ...prev,
            {
              name: file.name,
              preview: reader.result,
            },
          ]);
        };
        reader.readAsDataURL(file);
      }
    });

    if (errors.length) {
      setMessage({
        type: "error",
        text: errors.join(". "),
      });
      return;
    }

    try {
      setIsLoading(true);
      setMessage({ type: "", text: "" });

      for (const file of validFiles) {
        // Initialize progress for this file
        setUploadProgress((prev) => ({
          ...prev,
          [file.name]: 0,
        }));

        const uploadedFile = await uploadMediaFile(file, (progress) => {
          setUploadProgress((prev) => ({
            ...prev,
            [file.name]: progress,
          }));
        });

        setMediaFiles((prev) => [uploadedFile, ...prev]);

        // Clear progress for this file
        setUploadProgress((prev) => {
          const newProgress = { ...prev };
          delete newProgress[file.name];
          return newProgress;
        });
      }

      setMessage({ type: "success", text: "Files uploaded successfully!" });
      setPreviewFiles([]); // Clear previews after successful upload
    } catch (error) {
      setMessage({ type: "error", text: "Error uploading files" });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
    maxSize: MAX_FILE_SIZE,
  });

  const handleDeleteFile = async (fileId) => {
    try {
      setIsLoading(true);
      await deleteMediaFile(fileId);
      setMediaFiles((prev) => prev.filter((file) => file.fileId !== fileId));
      setMessage({ type: "success", text: "File deleted successfully!" });
    } catch (error) {
      console.error("Delete error:", error);
      setMessage({
        type: "error",
        text: error.message || "Error deleting file",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDelete = (fileId) => {
    setDeleteModal({
      isOpen: true,
      fileId,
    });
  };

  return (
    <div className="space-y-6 animate-fadeIn">
      {message.text && <Message type={message.type} text={message.text} />}

      {/* Header with actions */}
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-xl font-semibold">Media Library</h3>
          <p className="text-sm text-gray-500 mt-1">
            Manage your uploaded images and survey logos
          </p>
        </div>
        <Tooltip content="Toggle view">
          <button
            onClick={() =>
              setViewMode((prev) => (prev === "grid" ? "list" : "grid"))
            }
            className="p-2 text-gray-600 hover:text-gray-900"
          >
            {viewMode === "grid" ? <List size={20} /> : <Grid size={20} />}
          </button>
        </Tooltip>
      </div>

      {/* Upload Area */}
      <div
        {...getRootProps()}
        className={`
          border-2 border-dashed rounded-xl p-8 text-center cursor-pointer
          transition-colors duration-200
          ${
            isDragActive
              ? "border-black bg-gray-50"
              : "border-gray-200 hover:border-gray-300"
          }
        `}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center space-y-2">
          <div className="p-3 bg-gray-100 rounded-full">
            <Upload className="w-6 h-6 text-gray-600" />
          </div>
          <p className="text-gray-600">
            {isDragActive
              ? "Drop the files here..."
              : "Drag & drop files here, or click to select files"}
          </p>
          <p className="text-sm text-gray-500">
            Supports: PNG, JPG, GIF up to 10MB
          </p>
        </div>
      </div>

      {/* Preview Area */}
      {previewFiles.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {previewFiles.map((file, index) => (
            <div
              key={index}
              className="relative aspect-square bg-gray-50 rounded-lg overflow-hidden"
            >
              <img
                src={file.preview}
                alt={file.name}
                className="w-full h-full object-contain"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
                <p className="text-sm truncate">{file.name}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Media Grid/List */}
      {isLoading ? (
        <div className="flex justify-center items-center py-12">
          <Loader2 className="animate-spin" size={24} />
        </div>
      ) : mediaFiles.length === 0 ? (
        <EmptyState />
      ) : (
        <div
          className={
            viewMode === "grid"
              ? "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
              : "space-y-2"
          }
        >
          {mediaFiles.map((file) => (
            <div
              key={file.fileId}
              className="group relative bg-white border border-gray-200 rounded-xl overflow-hidden"
            >
              <div className="aspect-square relative bg-gray-50 flex items-center justify-center p-4">
                <img
                  src={file.url}
                  alt={file.name}
                  className="object-contain w-full h-full"
                />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-200">
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100">
                    <div className="flex space-x-2">
                      <Tooltip content="View image">
                        <button
                          onClick={() => window.open(file.url, "_blank")}
                          className="p-2 bg-white text-black rounded-lg hover:bg-gray-100 transition-colors"
                        >
                          <Eye size={20} />
                        </button>
                      </Tooltip>
                      {!file.isLogo && (
                        <Tooltip content="Delete image">
                          <button
                            onClick={() => confirmDelete(file.fileId)}
                            className="p-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                          >
                            <Trash2 size={20} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 bg-white border-t border-gray-200">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium truncate" title={file.name}>
                    {file.name}
                  </p>
                  {file.isLogo && (
                    <span className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded-full">
                      Logo
                    </span>
                  )}
                </div>
                <p className="text-xs text-gray-500 mt-1">
                  {new Date(file.createdAt).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Upload Progress */}
      <UploadProgress
        uploads={uploadProgress}
        onCancel={() => setUploadProgress({})}
      />

      <ConfirmationModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, fileId: null })}
        onConfirm={() => handleDeleteFile(deleteModal.fileId)}
        title="Delete File"
        message="Are you sure you want to delete this file? This action cannot be undone."
      />
    </div>
  );
};

export default MediaLibraryTab;
