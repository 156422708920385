import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSurveys,
  createSurvey,
  deleteSurvey,
  duplicateSurvey,
  testCreateAndFetchSurvey,
  getSurveyWithResponses,
} from "./serverComm";
import { Eye, Link2, Plus, Folder } from "lucide-react";
import { getAuthInstance } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import copy from "clipboard-copy";
import frame39 from "./frame-39.svg";
import startWithTemplate from "./start-with-temp.svg";
import pollPrevBg from "./poll-prev-bg.svg";
import LoadingAnimation from "./components/LoadingAnimation";
import Templates from "./Templates";
import WorkspaceModal from "./components/WorkspaceModal";
import ProfileSettings from "./pages/ProfileSettings";
import { createPortal } from "react-dom";
import ResponseLimitAlert from "./components/ResponseLimitAlert";

const backgroundColors = [
  "#FFD2D5", // Pink
  "#FFE8CE", // Orange
  "#DCE1FF", // Blue
  "#FFFEC1", // Yellow
  "#D3FBF1", // Mint
];

const BackgroundSVG = () => (
  <img
    src={pollPrevBg}
    alt=""
    className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
    style={{ mixBlendMode: "soft-light" }}
  />
);

const WorkspaceItem = ({ name, isActive, onSelect, count }) => (
  <div
    onClick={onSelect}
    className={`
      flex items-center justify-between p-3 rounded-lg cursor-pointer
      ${isActive ? "bg-black text-white" : "hover:bg-gray-100"}
    `}
  >
    <div className="flex items-center gap-2">
      <Folder className="w-4 h-4" />
      <span className="text-sm font-medium">{name}</span>
    </div>
    <span
      className={`
      text-xs font-medium
      ${isActive ? "text-white/70" : "text-gray-400"}
    `}
    >
      {count}
    </span>
  </div>
);

const ResponsesCounter = ({ used, subscription }) => {
  const limit = subscription?.limit;
  const isUnlimited = limit === null || limit === undefined;

  // Calculate the percentage for the progress bar
  const percentage = isUnlimited ? 0 : Math.min((used / limit) * 100, 100);

  // Determine if we're approaching the limit (e.g., 80% or more)
  const isApproachingLimit = percentage >= 80;

  return (
    <div className="px-4 py-3 bg-gray-50 rounded-lg">
      <div className="flex flex-col gap-1 mb-2">
        <span className="text-sm font-medium text-gray-700">
          Responses collected
        </span>
        <div className="flex items-baseline">
          <span className="text-2xl font-bold text-black">{used}</span>
          <span className="text-sm font-medium text-gray-500 ml-1">
            / {limit || "∞"}
          </span>
        </div>
      </div>
      {!isUnlimited && (
        <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
          <div
            className={`h-full rounded-full transition-all duration-300 ${
              used >= limit
                ? "bg-red-500"
                : isApproachingLimit
                ? "bg-yellow-500"
                : "bg-black"
            }`}
            style={{ width: `${percentage}%` }}
          />
        </div>
      )}
    </div>
  );
};

const UpgradeButton = ({ onClick }) => (
  <button
    onClick={onClick}
    className="w-full px-4 py-2.5 bg-[#fffec0] border border-[#e4e7eb] hover:bg-[#fffec0]/90 rounded-lg transition-all flex items-center justify-between"
  >
    <span className="text-sm font-medium text-black">
      Increase response limit
    </span>
    <span className="text-black">→</span>
  </button>
);

const WelcomeSection = ({
  userName,
  onNewSurvey,
  onOpenTemplates,
  isDisabled,
  onUpgradeClick,
}) => (
  <>
    <div className="flex justify-between items-center mb-6 sm:mb-8">
      <h1 className="text-[18px] sm:text-[21px] leading-[24px] sm:leading-[31px] font-['HK_Nova'] font-medium">
        Welcome to PollBolt, {userName || "Guest"}. ⚡⚡
      </h1>
    </div>

    <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 mb-6 sm:mb-8">
      <div
        onClick={(e) => {
          e.preventDefault();
          if (isDisabled) {
            onUpgradeClick(e);
          } else {
            onNewSurvey();
          }
        }}
        className={`
          bg-[#FFFD63] p-5 sm:p-7 rounded-[24px] shadow-[0_4px_10px_rgba(0,0,0,0.08)] 
          border border-transparent relative cursor-pointer
          ${
            isDisabled
              ? "opacity-50"
              : "hover:shadow-[0_4px_20px_rgba(0,0,0,0.12)] hover:border-[#eeeef0]"
          }
          transition-all flex items-center justify-center gap-4 w-full sm:max-w-[294px]
        `}
      >
        <img
          src={frame39}
          alt="New Survey"
          className="w-6 sm:w-8 h-6 sm:h-8 flex-shrink-0"
        />
        <span className="text-black font-bold text-[16px] sm:text-[18px] leading-4 tracking-[-0.21px] whitespace-nowrap">
          Start from scratch
        </span>
      </div>

      <div
        onClick={(e) => {
          e.preventDefault();
          if (isDisabled) {
            onUpgradeClick(e);
          } else {
            onOpenTemplates();
          }
        }}
        className={`
          bg-white p-5 sm:p-7 rounded-[24px] shadow-[0_4px_10px_rgba(0,0,0,0.08)]
          border border-transparent relative cursor-pointer
          ${
            isDisabled
              ? "opacity-50"
              : "hover:shadow-[0_4px_20px_rgba(0,0,0,0.12)] hover:border-[#eeeef0]"
          }
          transition-all flex items-center justify-center gap-4 w-full sm:max-w-[294px]
        `}
      >
        <img
          src={startWithTemplate}
          alt="Template"
          className="w-6 sm:w-8 h-6 sm:h-8 flex-shrink-0"
        />
        <span className="text-black font-bold text-[16px] sm:text-[18px] leading-4 tracking-[-0.21px] whitespace-nowrap">
          Start with Template
        </span>
      </div>
    </div>
  </>
);

const WorkspaceSubmenu = ({
  workspaces,
  onSelect,
  activeWorkspace,
  onClose,
  buttonRect,
}) => {
  if (!buttonRect) return null;

  return createPortal(
    <div
      className="fixed w-[160px] bg-white rounded-xl shadow-lg border border-gray-100 p-1.5"
      style={{
        zIndex: 9999,
        left: `${buttonRect.right + 8}px`,
        top: `${buttonRect.top}px`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {workspaces.map((workspace) => (
        <button
          key={workspace.id}
          onClick={() => {
            onSelect(workspace.id);
            onClose();
          }}
          className={`
            flex items-center w-full px-3 py-2 text-[14px] rounded-lg transition-all group
            ${
              workspace.id === activeWorkspace
                ? "bg-gray-50 text-black"
                : "text-gray-700 hover:bg-gray-50"
            }
          `}
        >
          <Folder className="w-4 h-4 mr-2.5 text-gray-400 group-hover:text-gray-600" />
          <span className="font-medium">{workspace.name}</span>
        </button>
      ))}
    </div>,
    document.body
  );
};

const ResponseLimitModal = ({
  isOpen,
  onClose,
  subscription,
  setIsProfileSettingsOpen,
}) => {
  if (!isOpen) return null;

  const getPlanLimit = () => {
    if (!subscription) return "2";

    const { limit, name } = subscription;

    // Adjust the logic based on your subscription names
    switch (name) {
      case "Pro Plan":
        return `${limit.toLocaleString()} per survey`;
      case "Growth Plan":
        return "unlimited";
      case "Free Trial":
      default:
        return limit.toLocaleString();
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-3xl shadow-xl max-w-[480px] w-full mx-4">
        <div className="text-center mb-8">
          <h3 className="text-3xl font-medium mb-4 font-['HK_Nova']">
            Response Limit Reached
          </h3>
          <p className="text-gray-600 mb-4">
            You have reached the maximum response limit for your current plan (
            {getPlanLimit()} responses).
          </p>
          <p className="text-gray-600">
            To continue collecting responses, please upgrade your plan.
          </p>
        </div>
        <div className="flex gap-3">
          <button
            onClick={onClose}
            className="w-1/2 h-14 text-lg rounded-xl border border-gray-200 hover:bg-gray-50 transition-all font-medium"
          >
            Close
          </button>
          <button
            onClick={() => {
              onClose();
              setIsProfileSettingsOpen(true);
            }}
            className="w-1/2 h-14 text-lg rounded-xl bg-black text-white hover:bg-black/90 transition-all font-medium"
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  );
};

const SurveyList = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTestingAPI, setIsTestingAPI] = useState(false);
  const auth = getAuthInstance();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [copiedId, setCopiedId] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState([
    { id: 1, name: "Personal", count: 0 },
    { id: 2, name: "Work", count: 0 },
    { id: 3, name: "Side Projects", count: 0 },
  ]);
  const [activeWorkspace, setActiveWorkspace] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [responsesUsed, setResponsesUsed] = useState(0);
  const [responsesLimit, setResponsesLimit] = useState(30);
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isProfileSettingsOpen, setIsProfileSettingsOpen] = useState(false);
  const [showWorkspaceSubmenu, setShowWorkspaceSubmenu] = useState(null);
  const [submenuButtonRect, setSubmenuButtonRect] = useState(null);
  const [showLimitModal, setShowLimitModal] = useState(false);

  const hasReachedResponseLimit = (survey, subscription) => {
    if (!subscription) return false;

    const { limit, name } = subscription;

    // If no response limit is set (unlimited)
    if (limit === null || limit === undefined) return false;

    const surveyResponses = survey.responses?.length || 0;
    const totalResponses = surveys.reduce(
      (sum, s) => sum + (s.responses?.length || 0),
      0
    );

    // Adjust the logic based on your subscription names
    switch (name) {
      case "Pro Plan":
        // Pro plan: Check per-survey limit
        return surveyResponses >= limit;
      case "Growth Plan":
        // Growth plan: Unlimited responses
        return false;
      case "Free Trial":
      default:
        // Free plan: Check total responses across all surveys
        return totalResponses >= limit;
    }
  };

  const testAPI = async () => {
    try {
      setIsTestingAPI(true);
      const result = await testCreateAndFetchSurvey();

      await fetchSurveys(); // Refresh the surveys list
    } catch (error) {
      console.error("API test failed:", error);
    } finally {
      setIsTestingAPI(false);
    }
  };

  const fetchSurveys = async () => {
    if (!auth.currentUser) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);

      const fetchedSurveys = await getSurveys();

      if (!Array.isArray(fetchedSurveys)) {
        console.error("Fetched surveys is not an array:", fetchedSurveys);
        setSurveys([]);
        return;
      }

      // Fetch detailed survey data with responses for each survey
      const surveysWithResponses = await Promise.all(
        fetchedSurveys.map(async (survey) => {
          try {
            if (!survey || !survey.surveyId) return null; // Skip invalid surveys
            const detailedSurvey = await getSurveyWithResponses(
              survey.surveyId
            );
            return {
              ...survey,
              responses: detailedSurvey.responses || [],
            };
          } catch (error) {
            console.error(
              `Error fetching responses for survey ${survey?.surveyId}:`,
              error
            );
            return null;
          }
        })
      );

      // Filter out null values and invalid surveys
      const validSurveys = surveysWithResponses.filter(
        (survey) => survey && survey.surveyId && !survey.isDeleted
      );

      setSurveys(validSurveys);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setError("Failed to load surveys");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCurrentSubscription = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      // Fix the API URL - make sure it matches your server URL
      const API_URL =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3001"
          : "https://surv-server.vercel.app";

      const response = await fetch(
        `${API_URL}/api/stripe/current-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch subscription");
      }

      const data = await response.json();

      // Make sure we're setting the subscription with the correct structure
      if (data.subscription) {
        setCurrentSubscription({
          namme: data?.subscription?.name,
          limit: data?.subscription?.responseLimit,
        });
      }
    } catch (err) {
      console.error("Error fetching current subscription:", err);
      setCurrentSubscription(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!auth.currentUser) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        await Promise.all([fetchSurveys(), fetchCurrentSubscription()]);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [auth.currentUser]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openMenuId) {
        const isClickOutside =
          !event.target.closest(".settings-menu") &&
          !event.target.closest(".settings-button");
        if (isClickOutside) {
          setOpenMenuId(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [openMenuId]);

  useEffect(() => {
    const countTotalResponses = () => {
      const total = surveys.reduce(
        (sum, survey) => sum + (survey.responses?.length || 0),
        0
      );
      setResponsesUsed(total);
    };

    countTotalResponses();
  }, [surveys]);

  useEffect(() => {
    setWorkspaces((prevWorkspaces) =>
      prevWorkspaces.map((workspace) => ({
        ...workspace,
        // For now, assign all surveys to Personal workspace
        count: workspace.id === 1 ? surveys.length : 0,
      }))
    );
  }, [surveys]);

  useEffect(() => {
    const checkResponseLimits = () => {
      const totalResponses = surveys.reduce(
        (sum, survey) => sum + (survey.responses?.length || 0),
        0
      );
      setResponsesUsed(totalResponses);

      // Check if any survey has reached its limit
      const hasLimitReached = surveys.some((survey) =>
        hasReachedResponseLimit(survey, currentSubscription)
      );

      if (hasLimitReached) {
        setShowLimitModal(true);
      }
    };

    checkResponseLimits();
  }, [surveys, currentSubscription]);

  const handleNewSurvey = async () => {
    try {
      setIsLoading(true);
      const response = await createSurvey();

      navigate(`/survey/${response.surveyId}`);
    } catch (error) {
      console.error("Error creating new survey:", error);
      setError("Failed to create survey");
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewSurvey = (e, surveyId) => {
    e.stopPropagation(); // Prevent the card click event from firing
    window.open(`/s/${surveyId}`, "_blank");
  };

  const handleViewAnalytics = (e, surveyId) => {
    e.stopPropagation();
    setOpenMenuId(null);
    navigate(`/survey/${surveyId}/analytics`, { replace: true });
  };

  const handleDeleteClick = async (e, survey) => {
    e.stopPropagation();

    if (!survey || !survey.surveyId) {
      console.error("Invalid survey data:", survey);
      return;
    }

    setSurveyToDelete(survey);
    setDeleteModalOpen(true);
    setOpenMenuId(null);
  };

  const handleConfirmDelete = async () => {
    if (!surveyToDelete || !surveyToDelete.surveyId) {
      console.error("No valid survey to delete");
      return;
    }

    try {
      setDeletingId(surveyToDelete.surveyId);

      // Call the delete API
      await deleteSurvey(surveyToDelete.surveyId);

      // Update local state only after successful deletion
      setSurveys((prevSurveys) =>
        prevSurveys.filter((s) => s.surveyId !== surveyToDelete.surveyId)
      );

      // Reset modal state
      setDeleteModalOpen(false);
      setSurveyToDelete(null);
    } catch (error) {
      console.error("Error deleting survey:", error);
      alert("Failed to delete survey. Please try again.");
    } finally {
      setDeletingId(null);
    }
  };

  const handleCopyLink = (e, surveyId) => {
    e.stopPropagation();
    const link = `${window.location.origin}/s/${surveyId}`;
    copy(link)
      .then(() => {
        setCopiedId(surveyId);
        setTimeout(() => setCopiedId(null), 3000);
      })
      .catch((err) => console.error("Failed to copy:", err));
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSettingsClick = (e, surveyId) => {
    e.stopPropagation();
    setOpenMenuId((current) => (current === surveyId ? null : surveyId));
  };

  const handleMenuMouseLeave = () => {
    setOpenMenuId(null);
  };

  const handleDuplicateSurvey = async (e, surveyId) => {
    e.stopPropagation();
    try {
      setIsDuplicating(true);
      setOpenMenuId(null); // Close menu immediately

      const duplicatedSurvey = await duplicateSurvey(surveyId);

      // Add the new survey to the list
      setSurveys((prevSurveys) => [
        {
          ...duplicatedSurvey,
          responses: [], // Initialize with empty responses
          viewCount: 0, // Initialize with 0 views
        },
        ...prevSurveys,
      ]);

      // Show success message (optional)
      // toast.success('Survey duplicated successfully');
    } catch (error) {
      console.error("Error duplicating survey:", error);
      alert("Failed to duplicate survey. Please try again.");
    } finally {
      setIsDuplicating(false);
    }
  };

  const getBackgroundColor = (index) => {
    return backgroundColors[index % backgroundColors.length];
  };

  const handleUpgradeClick = (e) => {
    e.preventDefault();
    setIsProfileSettingsOpen(true);
  };

  const handleCardClick = (surveyId) => {
    setOpenMenuId(null);
    // Check if the survey has reached its limit
    const survey = surveys.find((s) => s.surveyId === surveyId);
    if (hasReachedResponseLimit(survey, currentSubscription)) {
      // Show the response limit modal instead of directly opening profile settings
      setShowLimitModal(true);
    } else {
      navigate(`/survey/${surveyId}`);
    }
  };

  const hasReachedTotalResponseLimit = () => {
    if (!currentSubscription) return false;
    const { limit, name } = currentSubscription;

    // If no response limit is set (unlimited)
    if (limit === null || limit === undefined) return false;

    const totalResponses = surveys.reduce(
      (sum, survey) => sum + (survey.responses?.length || 0),
      0
    );

    // Adjust the logic based on your subscription names
    switch (name) {
      case "Pro Plan":
        // Pro plan: No total limit, only per-survey limits
        return false;
      case "Growth Plan":
        // Growth plan: Unlimited responses
        return false;
      case "Free Trial":
      default:
        // Free plan: Check total responses
        return totalResponses >= limit;
    }
  };

  const renderEmptyState = () => (
    <div className="w-full sm:w-[80%] mx-auto min-h-screen bg-white py-24 sm:py-24 px-4 sm:px-0">
      <WelcomeSection
        userName={auth.currentUser?.displayName}
        onNewSurvey={handleNewSurvey}
        onOpenTemplates={() => setIsTemplateModalOpen(true)}
        isDisabled={hasReachedTotalResponseLimit()}
        onUpgradeClick={(e) => {
          e.preventDefault();
          setShowLimitModal(true);
        }}
      />
    </div>
  );

  const handleCreateWorkspace = (name) => {
    setWorkspaces((prev) => [
      ...prev,
      {
        id: prev.length + 1,
        name: name,
        count: 0,
      },
    ]);
  };

  const handleMoveSurvey = async (surveyId, newWorkspaceId) => {
    try {
      // Here you would typically make an API call to update the survey's workspace
      // For now, we'll just update the local state
      setSurveys((prevSurveys) =>
        prevSurveys.map((survey) =>
          survey.surveyId === surveyId
            ? { ...survey, workspaceId: newWorkspaceId }
            : survey
        )
      );

      // Update workspace counts
      setWorkspaces((prevWorkspaces) =>
        prevWorkspaces.map((workspace) => ({
          ...workspace,
          count:
            workspace.id === newWorkspaceId
              ? workspace.count + 1
              : workspace.id === activeWorkspace
              ? workspace.count - 1
              : workspace.count,
        }))
      );
    } catch (error) {
      console.error("Error moving survey:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showWorkspaceSubmenu !== null) {
        const isClickInside = event.target.closest(".settings-menu");
        if (!isClickInside) {
          setShowWorkspaceSubmenu(null);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showWorkspaceSubmenu]);

  // Add this function to handle survey submission attempts
  const handleSurveySubmission = (surveyId) => {
    const survey = surveys.find((s) => s.surveyId === surveyId);
    if (!survey) return false;

    if (hasReachedResponseLimit(survey, currentSubscription)) {
      setShowLimitModal(true);
      return false; // Block submission
    }

    return true; // Allow submission
  };

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (error) {
    return (
      <div className="w-full h-screen flex items-center justify-center text-red-600">
        {error}
      </div>
    );
  }

  if (!surveys.length) {
    return renderEmptyState();
  }

  return (
    <div className="flex min-h-screen bg-white">
      {/* Sidebar */}
      <div
        className={`
        fixed left-0 top-0 h-screen bg-white border-r border-gray-200
        transition-all duration-300 z-30 pt-24
        ${isSidebarOpen ? "w-64" : "w-0 -translate-x-full"}
      `}
      >
        <div className="p-4">
          {/* Workspace Header */}
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-sm font-semibold text-gray-600">WORKSPACES</h2>
            <button
              className="w-7 h-7 flex items-center justify-center rounded-md hover:bg-gray-100"
              onClick={() => setIsWorkspaceModalOpen(true)}
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>

          {/* Workspace List */}
          <div className="space-y-1">
            {workspaces.map((workspace) => (
              <WorkspaceItem
                key={workspace.id}
                name={workspace.name}
                count={workspace.count}
                isActive={workspace.id === activeWorkspace}
                onSelect={() => setActiveWorkspace(workspace.id)}
              />
            ))}
          </div>

          {/* Workspace Settings */}
          <div className="absolute bottom-8 left-0 right-0 px-4">
            <div className="space-y-2">
              <ResponsesCounter
                used={responsesUsed}
                subscription={currentSubscription}
              />
              <UpgradeButton onClick={handleUpgradeClick} />
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div
        className={`
        flex-1 transition-all duration-300
        ${isSidebarOpen ? "ml-64" : "ml-0"}
      `}
      >
        {(isLoading || isDuplicating) && <LoadingAnimation />}

        <div className="w-full sm:w-[80%] mx-auto min-h-screen bg-white py-24 sm:py-24 px-4 sm:px-0">
          <WelcomeSection
            userName={auth.currentUser?.displayName}
            onNewSurvey={handleNewSurvey}
            onOpenTemplates={() => setIsTemplateModalOpen(true)}
            isDisabled={hasReachedTotalResponseLimit()}
            onUpgradeClick={(e) => {
              e.preventDefault();
              setShowLimitModal(true);
            }}
          />
          {surveys.some((survey) =>
            hasReachedResponseLimit(survey, currentSubscription)
          ) && (
            <ResponseLimitAlert
              subscription={currentSubscription}
              onUpgradeClick={() => setShowLimitModal(true)}
            />
          )}
          <div className="mb-4 sm:mb-6">
            <h2 className="text-black text-[18px] sm:text-[21px] text-left font-['HK_Nova'] font-medium leading-[21px]">
              All Forms
            </h2>
          </div>

          <div className="w-full h-px bg-gray-200 mb-4 sm:mb-6"></div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {surveys.map((survey, index) => {
              const isLimitReached = hasReachedResponseLimit(
                survey,
                currentSubscription
              );

              return (
                <div
                  key={survey.surveyId}
                  onClick={() => handleCardClick(survey.surveyId)}
                  className={`relative bg-white border border-[#e4e4e4] rounded-[15px] 
                    transition-all duration-200 hover:shadow-lg hover:-translate-y-1 cursor-pointer 
                    h-[280px] sm:h-[320px] ${
                      isLimitReached ? "opacity-75" : ""
                    }`}
                >
                  {isLimitReached && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black/5 rounded-[15px]">
                      <div
                        className="bg-white/95 px-4 py-2 rounded-lg shadow-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowLimitModal(true); // Open the response limit modal instead
                        }}
                      >
                        <span className="text-red-600 font-medium">
                          Response Limit Reached
                        </span>
                      </div>
                    </div>
                  )}
                  <div
                    className="absolute inset-x-2.5 sm:inset-x-3.5 top-2.5 sm:top-3.5 h-[180px] sm:h-[200px] rounded-[10px] overflow-visible border border-[#e4e4e4]"
                    style={{ backgroundColor: getBackgroundColor(index) }}
                  >
                    <div className="relative w-full h-full">
                      <img
                        src={pollPrevBg}
                        alt=""
                        className="absolute right-0 top-0 pointer-events-none w-[301px] h-[294px]"
                        style={{ mixBlendMode: "soft-light" }}
                      />

                      <div className="absolute top-3 sm:top-4 right-3 sm:right-4 z-20">
                        <div className="relative flex justify-end">
                          <button
                            onClick={(e) =>
                              handleSettingsClick(e, survey.surveyId)
                            }
                            className="settings-button w-[38px] h-[38px] flex items-center justify-center hover:bg-black/10 rounded-[10px] transition-colors flex-shrink-0"
                          >
                            <svg
                              className="w-5 h-5 text-black flex-shrink-0"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              stroke="none"
                            >
                              <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
                            </svg>
                          </button>

                          {openMenuId === survey.surveyId && (
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="settings-menu absolute w-[160px] bg-white/95 rounded-xl shadow-lg border border-gray-100 p-1.5 backdrop-blur-sm"
                              style={{
                                top: "100%",
                                right: "0px",
                                marginTop: "8px",
                                zIndex: 9000,
                              }}
                            >
                              <div className="py-0.5">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewAnalytics(e, survey.surveyId);
                                    setOpenMenuId(null);
                                  }}
                                  className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                >
                                  <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                    <svg
                                      className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path d="M18 20V10M12 20V4M6 20v-6" />
                                    </svg>
                                  </div>
                                  <span className="font-medium">Analytics</span>
                                </button>

                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDuplicateSurvey(e, survey.surveyId);
                                    setOpenMenuId(null);
                                  }}
                                  className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                >
                                  <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                    <svg
                                      className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <rect
                                        x="9"
                                        y="9"
                                        width="13"
                                        height="13"
                                        rx="2"
                                        ry="2"
                                      />
                                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
                                    </svg>
                                  </div>
                                  <span className="font-medium">Duplicate</span>
                                </button>

                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteClick(e, survey);
                                    setOpenMenuId(null);
                                  }}
                                  className="flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                >
                                  <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                    <svg
                                      className="w-full h-full text-gray-400 group-hover:text-gray-600 transition-colors"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                    </svg>
                                  </div>
                                  <span className="font-medium">Delete</span>
                                </button>

                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const rect =
                                      e.currentTarget.getBoundingClientRect();
                                    setSubmenuButtonRect(rect);
                                    setShowWorkspaceSubmenu((current) =>
                                      current === survey.surveyId
                                        ? null
                                        : survey.surveyId
                                    );
                                  }}
                                  className="relative flex items-center w-full px-3 py-2 text-[14px] text-gray-700 hover:bg-gray-50 rounded-lg transition-colors group"
                                >
                                  <div className="w-4 h-4 mr-2.5 flex-shrink-0">
                                    <Folder className="w-full h-full text-gray-400 group-hover:text-gray-600" />
                                  </div>
                                  <span className="font-medium">Move to</span>
                                  <svg
                                    className="w-4 h-4 ml-auto text-gray-400 flex-shrink-0"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    <path d="M9 18l6-6-6-6" />
                                  </svg>

                                  {showWorkspaceSubmenu === survey.surveyId && (
                                    <WorkspaceSubmenu
                                      workspaces={workspaces}
                                      activeWorkspace={
                                        survey.workspaceId || activeWorkspace
                                      }
                                      onSelect={(workspaceId) =>
                                        handleMoveSurvey(
                                          survey.surveyId,
                                          workspaceId
                                        )
                                      }
                                      onClose={() => {
                                        setShowWorkspaceSubmenu(null);
                                        setOpenMenuId(null);
                                        setSubmenuButtonRect(null);
                                      }}
                                      buttonRect={submenuButtonRect}
                                    />
                                  )}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="absolute left-[20px] sm:left-[24px] bottom-[16px] sm:bottom-[20px]">
                        <h2 className="text-[16px] sm:text-[18px] font-['HK_Nova'] font-medium leading-[20px] sm:leading-[22px] tracking-[-0.93px] text-black mb-3 max-w-[260px] sm:max-w-[280px]">
                          {survey.title}
                        </h2>
                        <div className="flex items-center gap-2">
                          <div
                            className={`
                            flex items-center gap-2 px-3 py-1.5 rounded-full
                            ${
                              survey.isOpen
                                ? "bg-[#ECFDF3] text-[#027A48]"
                                : "bg-[#FFF1F3] text-[#C01048]"
                            }
                          `}
                          >
                            <div
                              className={`
                              w-1.5 h-1.5 rounded-full
                              ${survey.isOpen ? "bg-[#027A48]" : "bg-[#C01048]"}
                            `}
                            />
                            <span className="text-sm font-medium">
                              {survey.isOpen ? "Active" : "Closed"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute inset-x-[24px] sm:inset-x-[32px] bottom-[20px] sm:bottom-[24px] flex items-center justify-between">
                    <div>
                      <div className="text-sm font-medium text-[#7b7b7d] tracking-[-0.48px] mb-1">
                        Total responses
                      </div>
                      <div className="text-3xl font-bold text-black tracking-[-1.08px]">
                        {survey.responses?.length || 0}
                      </div>
                    </div>

                    <div className="flex items-center gap-3">
                      <div className="flex items-center gap-2 text-[#7b7b7d]">
                        <Eye className="w-4 h-4" />
                        <span className="text-sm font-medium tracking-[-0.48px]">
                          {survey.viewCount || 0}
                        </span>
                      </div>
                      <button
                        onClick={(e) => handleCopyLink(e, survey.surveyId)}
                        className="flex items-center text-sm font-medium text-[#7b7b7d] tracking-[-0.48px] hover:text-gray-900 transition-colors"
                      >
                        <Link2 className="w-4 h-4 mr-1.5" />
                        {copiedId === survey.surveyId ? "Copied!" : "Share"}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {deleteModalOpen && (
            <div
              className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
              onClick={() => setDeleteModalOpen(false)}
            >
              <div
                className="bg-white p-8 rounded-3xl shadow-xl max-w-[480px] w-full mx-4"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={() => setDeleteModalOpen(false)}
                  className="absolute right-6 top-6 text-gray-400 hover:text-gray-600"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path d="M18 6L6 18M6 6l12 12" />
                  </svg>
                </button>

                <div className="text-center mb-8">
                  <h3 className="text-3xl font-medium mb-2 font-['HK_Nova']">
                    Delete Poll
                  </h3>
                  <p className="text-gray-500">
                    Are you sure you want to delete "{surveyToDelete?.title}"?
                    <br />
                    This action cannot be undone.
                  </p>
                </div>

                <div className="flex gap-3">
                  <button
                    onClick={() => {
                      setDeleteModalOpen(false);
                      setSurveyToDelete(null);
                    }}
                    className="w-1/2 h-14 text-lg rounded-xl border border-gray-200 hover:bg-gray-50 transition-all font-medium"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleConfirmDelete}
                    disabled={deletingId !== null}
                    className="w-1/2 h-14 text-lg rounded-xl bg-black text-white hover:bg-black/90 transition-all font-medium disabled:opacity-50"
                  >
                    {deletingId === surveyToDelete?.surveyId
                      ? "Deleting..."
                      : "Delete Poll"}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Templates
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
      />

      <WorkspaceModal
        isOpen={isWorkspaceModalOpen}
        onClose={() => setIsWorkspaceModalOpen(false)}
        onCreateWorkspace={handleCreateWorkspace}
      />

      <ProfileSettings
        isOpen={isProfileSettingsOpen}
        onClose={() => setIsProfileSettingsOpen(false)}
        initialTab="billing"
      />

      {/* Add the ResponseLimitModal */}
      <ResponseLimitModal
        isOpen={showLimitModal}
        onClose={() => setShowLimitModal(false)}
        subscription={currentSubscription}
        setIsProfileSettingsOpen={setIsProfileSettingsOpen}
      />
    </div>
  );
};

export default SurveyList;
