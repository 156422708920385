import { Check } from "lucide-react";
import React, { useState } from "react";

const PlanCard = ({
  id,
  name,
  price,
  features,
  current,
  color,
  isAnnual,
  onSubscribe,
  currentSubscription,
  hasPaymentMethod,
  onNeedsPaymentMethod,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isDowngrade =
    (name === "Free" && currentSubscription) ||
    (name === "Pro" && currentSubscription?.name === "Growth");

  const handleSubscribeClick = () => {
    if (!hasPaymentMethod && name !== "Free") {
      onNeedsPaymentMethod();
      return;
    }
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    setIsLoading(true);
    try {
      await onSubscribe(id);
      setShowSuccess(true);
    } catch (err) {
      setError(
        err.message || "Failed to update subscription. Please try again."
      );
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlanDescription = (name) => {
    switch (name) {
      case "Free":
        return "Up to 3 Surveys";
      case "Pro":
        return "Unlimited Surveys";
      case "Growth":
        return "Ultimate Features";
    }
  };

  const getTagline = (name) => {
    switch (name) {
      case "Free":
        return "Enjoy free";
      case "Pro":
      case "Growth":
        return "20% OFF";
    }
  };

  const getSubtext = (name) => {
    switch (name) {
      case "Free":
        return "For individuals and small teams\njust getting started";
      case "Pro":
        return "For professionals who need\nmore power and customization";
      case "Growth":
        return "For teams that need\nadvanced features & support";
    }
  };

  return (
    <div className="w-full overflow-hidden rounded-2xl border border-gray-200 bg-white">
      <div className={`${color} p-4 sm:p-6 text-center`}>
        <h2 className="text-2xl font-medium mb-2">{name}</h2>
        <p className="text-lg mb-3">{getPlanDescription(name)}</p>
        <div className="inline-flex bg-white h-6 px-2 items-center rounded-full border border-black">
          <span className="font-bold text-sm whitespace-nowrap">
            {getTagline(name)}
          </span>
        </div>
      </div>

      <div className="p-4 sm:p-6">
        <div className="text-center mb-4">
          <div className="flex items-start justify-center">
            <span className="text-xl mt-2">$</span>
            <span className="text-5xl font-bold">{price}</span>
            <span className="text-gray-500 ml-1 mt-3">/mon</span>
          </div>
        </div>

        <p className="text-center text-sm mb-6 whitespace-pre-line">
          {getSubtext(name)}
        </p>

        {current ? (
          <button className="w-full h-12 text-base rounded-xl border border-black">
            Your current plan
          </button>
        ) : (
          <button
            onClick={handleSubscribeClick}
            className="w-full h-12 text-base rounded-xl bg-black text-white hover:bg-black/90"
          >
            {`Get ${name} Plan`}
          </button>
        )}

        <div className="space-y-3 mt-6">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center gap-2">
              <Check className="w-4 h-4 text-gray-400 flex-shrink-0" />
              {Array.isArray(feature) ? (
                <span className="text-gray-600 text-sm flex items-center">
                  {feature[0]}
                  <span className="group relative">
                    {feature[1]}
                    <span className="invisible group-hover:visible absolute -translate-x-1/2 -translate-y-full -top-1 left-1/2 w-64 bg-black text-white text-xs rounded-md py-2 px-3 z-[100]">
                      {feature[0] === "Custom branding"
                        ? "Custom logo and background image"
                        : "Facebook Pixel, Google Analytics, Google Tag Manager, Slack"}
                    </span>
                  </span>
                </span>
              ) : (
                <span className="text-gray-600 text-sm">{feature}</span>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirm}
        planName={name}
        isDowngrade={isDowngrade}
      />

      <SuccessModal
        isOpen={showSuccess}
        onClose={() => setShowSuccess(false)}
        planName={name}
      />

      <ErrorModal 
        isOpen={showError}
        onClose={() => setShowError(false)}
        error={error}
      /> */}
    </div>
  );
};

export default PlanCard;
