import React, { useState, useEffect, useSyncExternalStore } from "react";
import { Check, Loader2, Info } from "lucide-react";
import { loadStripe } from "@stripe/stripe-js";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuthInstance } from "./firebase";
import PlanCard from "./components/PlanCard";
import { Elements } from "@stripe/react-stripe-js";
import AddPaymentForm from "./components/ui/AddPaymentForm";

// Initialize Stripe outside component to avoid multiple instances
const stripe = await loadStripe(
  "pk_live_51NUwEkA00utCSExRWeyt9Tc1jsKoFdmmmdYjml5mVr80dXpMIpUZqEHZfUnRYs4xV60zZWicOmAT7NyIvvrIDbzB005Fzwaz0L"
);
const stripePromise = loadStripe(
  "pk_test_51NUwEkA00utCSExRUfexEaAeoS4LU5HC2vFXwyps0HZVyBWQzR9Whv1lbAKiDqudZ9ttyk4YWj5g18gYhMPVzHmV00tnvJFzt1"
);
// Utility function to prevent body scroll
const preventBodyScroll = (prevent) => {
  document.body.style.overflow = prevent ? "hidden" : "auto";
};

const PricingPage = ({ isOpen, onClose, openSignIn }) => {
  const auth = getAuthInstance();
  const [isAnnual, setIsAnnual] = useState(true);
  const [user] = useAuthState(getAuthInstance());
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPlan, setCurrentPlan] = useState("free");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_URL}/api/stripe/products`);
      const data = await response.json();
      setProducts(data.products);
    } catch (err) {
      console.error("Error fetching products:", err);
    }
  };
  const priceIds = {
    pro: {
      monthly: "price_1QQEetA00utCSExRMAUzdQYo",
      annual: "price_1QQEeuA00utCSExRiC72wvv5",
    },
    growth: {
      monthly: "price_1QQFhiA00utCSExRBUekBjRP",
      annual: "price_1QQFhiA00utCSExRN7a7loIB",
    },
  };

  const API_URL =
    process.env.NODE_ENV === "production"
      ? "https://your-production-url.com"
      : "http://localhost:3001";

  const handleSubscribe = async (plan) => {
    if (!user) {
      if (openSignIn) {
        openSignIn(true);
      } else {
        alert("Please sign in to subscribe");
      }
      return;
    }

    setIsLoading(true);
    try {
      const priceId = priceIds[plan][isAnnual ? "annual" : "monthly"];

      const token = await user.getIdToken();

      // First, test if server is reachable
      try {
        const testResponse = await fetch("/api/test");
      } catch (error) {
        console.error("Server test failed:", error);
      }

      const response = await fetch("/api/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ priceId }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server error:", {
          status: response.status,
          text: errorText,
          headers: Object.fromEntries(response.headers.entries()),
        });
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { sessionId } = await response.json();

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe redirect error:", error);
        throw error;
      }
    } catch (error) {
      console.error("Subscription error:", error);
      alert(error.message || "Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Update the renderUpgradeButton function
  const renderUpgradeButton = (plan) => {
    const isCurrentPlan = currentPlan === plan;

    if (!user) {
      return (
        <button
          onClick={() => handleSubscribe(plan)}
          className="w-full h-12 text-base rounded-xl bg-black text-white hover:bg-black/90"
        >
          Sign up for {plan.charAt(0).toUpperCase() + plan.slice(1)}
        </button>
      );
    }

    return (
      <button
        onClick={() => handleSubscribe(plan)}
        disabled={isCurrentPlan || isLoading}
        className={`w-full h-12 text-base rounded-xl ${
          isCurrentPlan
            ? "bg-gray-100 text-gray-500 cursor-not-allowed"
            : "bg-black text-white hover:bg-black/90"
        }`}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <Loader2 className="animate-spin mr-2" size={20} />
            Processing...
          </span>
        ) : isCurrentPlan ? (
          "Current Plan"
        ) : (
          `Upgrade to ${plan.charAt(0).toUpperCase() + plan.slice(1)}`
        )}
      </button>
    );
  };
  const fetchCurrentSubscription = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(
        `${API_URL}/api/stripe/current-subscription`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setCurrentPlan(data.subscription);
    } catch (err) {
      console.error("Error fetching current subscription:", err);
    }
  };

  const fetchPaymentMethods = async () => {
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${API_URL}/api/stripe/payment-methods`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setPaymentMethods(data.paymentMethods);
    } catch (err) {
      console.error("Error fetching payment methods:", err);
    }
  };
  // Add useEffect for scroll management
  useEffect(() => {
    if (isOpen) {
      preventBodyScroll(true);
      fetchProducts();
      fetchCurrentSubscription();
    }
    setCustomerName(auth.currentUser?.displayName || "");
    setCustomerEmail(auth.currentUser?.email || "");
    return () => preventBodyScroll(false);
  }, [isOpen]);

  if (!isOpen) return null;

  const prices = {
    pro: {
      monthly: 18,
      annual: 14.4,
    },
    business: {
      monthly: 30,
      annual: 24,
    },
  };

  const plans = [
    {
      id: null, // Free plan has no Stripe product ID
      name: "Free",
      price: 0,
      current: !currentPlan || currentPlan.name === "Free",
      color: "bg-[#FFFEF2]",
      features: [
        "3 surveys total",
        "30 responses total",
        "Basic design only",
        "Email support",
      ],
    },
    ...products
      .filter((p) => ["Pro", "Growth"].includes(p.name))
      .map((product) => ({
        id: product.id,
        name: product.name,
        price: isAnnual ? product.monthlyPrice * 0.8 : product.monthlyPrice,
        current: currentPlan?.name === product.name,
        color: product.name === "Pro" ? "bg-[#FFFD63]" : "bg-[#D3FBF1]",
        features:
          product.name === "Pro"
            ? [
                "100 surveys total",
                "1,000 responses per survey",
                "Custom branding",
                [
                  "Basic integrations",
                  <Info
                    className="w-4 h-4 text-gray-400 inline-block ml-0.5 cursor-help"
                    data-tooltip="Facebook Pixel, Google Analytics, Google Tag Manager, Slack"
                  />,
                ],
                "Standard technical support",
              ]
            : [
                "Unlimited surveys and responses",
                "Team collaboration",
                "Advanced analytics",
                "Advanced integrations (API Access)",
                "Priority technical support",
                "Custom onboarding",
              ],
      })),
  ];

  const AddPaymentModal = ({
    isOpen,
    onClose,
    customerName,
    customerEmail,
  }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 w-96">
          <h3 className="text-lg font-semibold mb-4">Add Payment Method</h3>
          <Elements stripe={stripePromise}>
            <AddPaymentForm
              onClose={onClose}
              customerName={customerName}
              customerEmail={customerEmail}
            />
          </Elements>
        </div>
      </div>
    );
  };

  return (
    <div
      className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4 overflow-y-auto"
      onClick={() => onClose()}
    >
      <div
        className="bg-white rounded-3xl w-full max-w-[1200px] my-4 relative"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path d="M18 6L6 18M6 6l12 12" />
          </svg>
        </button>

        <div className="px-4 sm:px-6 py-8">
          {/* Header */}
          <h1 className="text-center text-2xl sm:text-3xl md:text-4xl font-medium mb-6 font-['HK_Nova']">
            Your Time to get some Answers
          </h1>

          {/* Pricing Toggle */}
          <div className="flex justify-center mb-8">
            <div className="inline-flex items-center rounded-[20px] bg-white border border-[#E4E4E4] p-1.5">
              <div className="flex items-center space-x-2">
                <div
                  className={`px-3 py-2 rounded-[16px] transition-colors duration-300 cursor-pointer font-medium
                  ${isAnnual ? "text-black" : "text-gray-400"}`}
                  onClick={() => setIsAnnual(true)}
                >
                  <span
                    className={`text-sm sm:text-base relative z-10 ${
                      isAnnual
                        ? "bg-[#FFFD63] -mx-3 -my-2 px-3 py-2 rounded-[16px] block"
                        : ""
                    }`}
                  >
                    Annual (3 month free)
                  </span>
                </div>

                <div
                  className={`px-3 py-2 rounded-[16px] transition-colors duration-300 cursor-pointer font-medium
                  ${!isAnnual ? "text-black" : "text-gray-400"}`}
                  onClick={() => setIsAnnual(false)}
                >
                  <span
                    className={`text-sm sm:text-base relative z-10 ${
                      !isAnnual
                        ? "bg-[#FFFD63] -mx-3 -my-2 px-3 py-2 rounded-[16px] block"
                        : ""
                    }`}
                  >
                    Monthly
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Pricing Cards Container */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1100px] mx-auto">
            {/* Free Plan */}
            {/* <div className="w-full overflow-hidden rounded-2xl border border-gray-200 bg-white">
              <div className="bg-[#FFFEF2] p-4 sm:p-6 text-center">
                <h2 className="text-2xl font-medium mb-2">Free</h2>
                <p className="text-lg mb-3">Up to 3 Surveys</p>
                <div className="inline-flex bg-white h-6 px-2 items-center rounded-full border border-black">
                  <span className="font-bold text-sm whitespace-nowrap">
                    Enjoy free
                  </span>
                </div>
              </div>

              <div className="p-4 sm:p-6">
                <div className="text-center mb-4">
                  <div className="flex items-start justify-center">
                    <span className="text-xl mt-2">$</span>
                    <span className="text-5xl font-bold">0</span>
                    <span className="text-gray-500 ml-1 mt-3">/mon</span>
                  </div>
                </div>

                <p className="text-center text-sm mb-6">
                  For individuals and small teams
                  <br />
                  just getting started
                </p>

                <button className="w-full h-12 text-base rounded-xl border border-black">
                  Your current plan
                </button>

                <div className="space-y-3 mt-6">
                  {[
                    "3 surveys total",
                    "30 responses total",
                    "Basic design only",
                    "Email support",
                  ].map((feature, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-gray-400" />
                      <span className="text-gray-600 text-sm">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            {/* Pro Plan */}
            {/* <div className="w-full overflow-hidden rounded-2xl border border-gray-200 bg-white">
              <div className="bg-[#FFFD63] p-4 sm:p-6 text-center">
                <h2 className="text-2xl font-medium mb-2">Pro</h2>
                <p className="text-lg mb-3">Unlimited Surveys</p>
                <div className="inline-flex bg-white h-6 px-2 items-center rounded-full border border-black">
                  <span className="font-bold text-sm whitespace-nowrap">
                    20% OFF
                  </span>
                </div>
              </div>

              <div className="p-4 sm:p-6">
                <div className="text-center mb-4">
                  <div className="flex items-start justify-center">
                    <span className="text-xl mt-2">$</span>
                    <span className="text-5xl font-bold">
                      {isAnnual ? prices.pro.annual : prices.pro.monthly}
                    </span>
                    <span className="text-gray-500 ml-1 mt-3">/mon</span>
                  </div>
                </div>

                <p className="text-center text-sm mb-6">
                  For professionals who need
                  <br />
                  more power and customization
                </p>

                {renderUpgradeButton("pro")}

                <div className="space-y-3 mt-6">
                  {[
                    "100 surveys total",
                    "1,000 responses per survey",
                    "Custom branding",
                    [
                      "Basic integrations",
                      <Info
                        className="w-4 h-4 text-gray-400 inline-block ml-0.5 cursor-help"
                        data-tooltip="Facebook Pixel, Google Analytics, Google Tag Manager, Slack"
                      />,
                    ],
                    "Standard technical support",
                  ].map((feature, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-gray-400 flex-shrink-0" />
                      <span className="text-gray-600 text-sm flex items-center">
                        {Array.isArray(feature) ? (
                          <>
                            {feature[0]}
                            <span className="group relative">
                              {feature[1]}
                              <span className="invisible group-hover:visible absolute -translate-x-1/2 -translate-y-full -top-1 left-1/2 w-64 bg-black text-white text-xs rounded-md py-2 px-3 z-[100]">
                                {feature[0] === "Custom branding"
                                  ? "Custom logo and background image"
                                  : "Facebook Pixel, Google Analytics, Google Tag Manager, Slack"}
                              </span>
                            </span>
                          </>
                        ) : (
                          feature
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}

            {/* Growth Plan */}
            {/* <div className="w-full overflow-hidden rounded-2xl border border-gray-200 bg-white">
              <div className="p-4 sm:p-6 text-center bg-[#D3FBF1]">
                <h2 className="text-2xl font-medium mb-2">Growth</h2>
                <p className="text-lg mb-3">Ultimate Features</p>
                <div className="inline-flex bg-white h-6 px-2 items-center rounded-full border border-black">
                  <span className="font-bold text-sm whitespace-nowrap">
                    20% OFF
                  </span>
                </div>
              </div>

              <div className="p-4 sm:p-6">
                <div className="text-center mb-4">
                  <div className="flex items-start justify-center">
                    <span className="text-xl mt-2">$</span>
                    <span className="text-5xl font-bold">
                      {isAnnual
                        ? prices.business.annual
                        : prices.business.monthly}
                    </span>
                    <span className="text-gray-500 ml-1 mt-3">/mon</span>
                  </div>
                </div>

                <p className="text-center text-sm mb-6">
                  For teams that need
                  <br />
                  advanced features & support
                </p>

                <button className="w-full h-12 text-base rounded-xl bg-black text-white hover:bg-black/90">
                  Get Growth Plan
                </button>

                <div className="space-y-3 mt-6">
                  {[
                    "Unlimited surveys and responses",
                    "Team collaboration",
                    "Advanced analytics",
                    "Advanced integrations (API Access)",
                    "Priority technical support",
                    "Custom onboarding",
                  ].map((feature, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <Check className="w-4 h-4 text-gray-400" />
                      <span className="text-gray-600 text-sm">{feature}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            {plans
              .sort((a, b) => {
                const order = { Free: 1, Pro: 2, Growth: 3 };
                return order[a.name] - order[b.name];
              })
              .map((plan) => (
                <PlanCard
                  key={plan.name}
                  {...plan}
                  isAnnual={isAnnual}
                  onSubscribe={handleSubscribe}
                  currentSubscription={currentPlan}
                  hasPaymentMethod={paymentMethods.length > 0}
                  variant="pricing"
                  onNeedsPaymentMethod={() => setShowAddPayment(true)}
                />
              ))}
          </div>
          <AddPaymentModal
            isOpen={showAddPayment}
            onClose={() => setShowAddPayment(false)}
            customerName={customerName}
            customerEmail={customerEmail}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
